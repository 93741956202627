import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import Typography from "@mui/material/Typography";
import { useState } from "react";

import RListButton from "@/shared/components/buttons/RListButton";
import RMenu from "@/shared/components/RMenu";

export default function ChangeRoleButton() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Typography onClick={handleOpen} sx={{ fontSize: "1.25rem" }}>
        Change role
      </Typography>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}>
        <RMenu>
          <RListButton isMenuItem>Member</RListButton>
          <Divider />
          <RListButton isMenuItem>Admin</RListButton>
          <Divider />
          <RListButton isMenuItem>Guest</RListButton>
        </RMenu>
      </Menu>
    </>
  );
}

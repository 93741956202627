export type RiskApiEndpoints = {
  GET_BASELINE_RISKS: string;
  UPDATE_RISKS: string;
  ADD_RISKS: string;
  DELETE_RISK: string;
};
export const RISK_API_ENDPOINTS: RiskApiEndpoints = {
  GET_BASELINE_RISKS: "/api/deliverableRisk/getAllDeliverableRisks/{wbsEntryId}",
  // GET_BASELINE_RISKS_TOTALS:
  //   "/api/deliverableResources/getCostEstimateSummary/{wbsEntryId}",
  UPDATE_RISKS: "/api/deliverableRisk/{riskId}",
  ADD_RISKS: "/api/deliverableRisk",
  DELETE_RISK: "/api/deliverableRisk/{riskId}",
};

type CostApiEndpoints = {
  GET_COST: string;
  EDIT_COST: string;
  DELETE_COST: string;
  ADD_RESOURCE: string;
  GET_COST_BASELINE_TOTALS: string;
};
export const COST_API_ENDPOINTS: CostApiEndpoints = {
  GET_COST: "api/scenarios/getLevel3DeliverableResources/{deliverableId}/{projectId}",
  EDIT_COST: "/api/deliverableResources",
  DELETE_COST: "/api/deliverableResources/{deliverableId}/{resourceId}",
  ADD_RESOURCE: "/api/deliverableresources/{deliverableId}/{resourceId}",
  GET_COST_BASELINE_TOTALS:
    "/api/deliverableResources/getCostEstimateSummary/{wbsEntryId}",
};

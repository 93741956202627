import { OrgChart } from "d3-org-chart";

import { IData } from "../types/orgChartModels";

export default function expandNodesByIds(chart: OrgChart<IData>, ids: string[]) {
  // Get chart nodes
  const chartData = chart.data();
  if (!chartData) return;

  // Filter out the nodes that are not in the list of ids
  const relevantNodes = chartData.filter((node) => ids.includes(node.id));

  // Update nodes: set '_expanded' to true for the nodes with matching ids
  // and their direct children
  const updatedData = chartData.map((node) => {
    const currentNode = node;
    if (
      ids.includes(currentNode.id) ||
      relevantNodes.some((parentNode) => parentNode.id === currentNode.parentId)
    ) {
      currentNode._expanded = true;
    }
    return currentNode;
  });

  // Update the chart data and re-render
  chart.data(updatedData).render();
}

export const getAllDescendantIdsIncludingChildren = (
  nodeId: string,
  chartData: IData[]
) => {
  let ids = [nodeId];
  chartData.forEach((node: IData) => {
    if (node.parentId === nodeId) {
      ids.push(node.id);
      ids = ids.concat(getAllDescendantIdsIncludingChildren(node.id, chartData));
    }
  });
  return ids;
};

/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison -- many errors not worth fixing since this feature needs to be refactored */
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import { MRT_Row } from "material-react-table";
import { useState, useRef } from "react";

import { Concept, PortfolioRowModel, Project } from "../models/portfolioModel";

import AddConceptButton from "./AddConceptButton";
import CloneConceptButton from "./CloneConceptButton";
import CloneProjectButton from "./CloneProjectButton";
import EditConceptButton from "./EditConceptButton";
import EditProjectButton from "./EditProjectButton";

type PortfolioRowActionsProps = {
  key: string;
  row: MRT_Row<PortfolioRowModel>;
  refetchProjects: () => Promise<void>;
  addCloneProject: (newProject: Project) => void;
  addCloneConcept: (newConcept: Concept) => void;
};

export default function PortfolioRowActions({
  row,
  refetchProjects,
  addCloneProject,
  addCloneConcept,
}: PortfolioRowActionsProps) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const anchorRef = useRef(null);

  const handleOpenMenu = () => {
    setAnchorEl(anchorRef.current);
    setIsMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setIsMenuOpen(false);
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton ref={anchorRef} onClick={handleOpenMenu}>
        <MoreVertIcon />
      </IconButton>
      <Popover
        open={isMenuOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handleCloseMenu}>
        {row.original.type === 1 && (
          <>
            <EditProjectButton
              row={row}
              closePopover={handleCloseMenu}
              refetchProjects={refetchProjects}
            />
            <AddConceptButton
              projectId={row.original.id}
              refetchProjects={refetchProjects}
              closePopover={handleCloseMenu}
            />
            <CloneProjectButton
              project={row.original}
              closePopover={handleCloseMenu}
              addCloneProject={addCloneProject}
            />
          </>
        )}
        {row.original.type === 2 && (
          <>
            <EditConceptButton
              row={row}
              closePopover={handleCloseMenu}
              refetchProjects={refetchProjects}
            />
            <CloneConceptButton
              concept={row.original}
              projectCode={(row.getParentRow()?.original?.children?.length ?? 0) + 1}
              addCloneConcept={addCloneConcept}
              closePopover={handleCloseMenu}
            />
          </>
        )}
      </Popover>
    </Box>
  );
}

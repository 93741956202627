import { Delete } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { useState } from "react";

import { TertiaryButton } from "@/shared/components/buttons";
import ReusableDialog from "@/shared/components/ReusableDialog";
import useProjectWBSContext from "@/shared/context/projectWBS/useProjectWBSContext";

import { deleteDeliverables } from "../api/projectScopeService";

export default function DeleteProjectScope({
  rowSelection,
  clearRowSelection,
}: {
  rowSelection: string[];
  clearRowSelection: () => void;
}) {
  const [isModalOpen, setModalOpen] = useState(false);
  const { refetchProjectWBS } = useProjectWBSContext();

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <TertiaryButton icon={<Delete fontSize="small" />} onClick={handleOpen}>
        Delete
      </TertiaryButton>
      <ReusableDialog
        isOpen={isModalOpen}
        title="Delete Selected Outcome"
        confirmButtonCallback={async () => {
          // Use Promise.all to wait for all deletions to complete
          await Promise.all(rowSelection.map((id) => deleteDeliverables(id)));
          clearRowSelection();
          refetchProjectWBS();
          handleClose();
        }}
        cancelButtonCallback={handleClose}
        confirmButtonText="Delete"
        confirmButtonType="error"
        size="small">
        <Typography variant="h5" sx={{ textAlign: "center" }}>
          Are you sure you want to delete the selected outcome?
        </Typography>
      </ReusableDialog>
    </>
  );
}

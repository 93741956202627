import { Typography, TypographyProps } from "@mui/material";
import { NumericFormat } from "react-number-format";

import { getUnit } from "../helpers/numberFormatHelpers";

type RNumberLabelProps = {
  value: string | number | null | undefined;
  decimalScale?: number;
  typographyProps?: TypographyProps;
  category:
    | "currency"
    | "emissions"
    | "duration"
    | "percentage"
    | "quantity"
    | "outcome"
    | "per outcome";
  customUnit?: string;
};
export default function RNumberLabel({
  value,
  customUnit,
  category,
  decimalScale = 0,
  typographyProps,
}: RNumberLabelProps) {
  // Ensure value defaults to 0 if it's undefined, null, or NaN
  const normalizedValue = value == null || isNaN(Number(value)) ? 0 : Number(value);
  // function that returns an object which contains suffix and prefix
  const unit = getUnit(category, customUnit);
  return (
    <Typography {...typographyProps} sx={{ textAlign: "right" }}>
      <NumericFormat
        value={normalizedValue}
        displayType="text"
        thousandSeparator
        fixedDecimalScale
        decimalScale={decimalScale}
        prefix={unit.prefix}
        suffix={unit.suffix}
      />
    </Typography>
  );
}

import { AxiosError, AxiosResponse } from "axios";

import { RESOURCE_CATALOGUE_ENDPOINTS } from "@/features/cost/resourceCatalogue/api/endpoints";
import apiRequests from "@/shared/config/axiosConfig";

import { ResourcesModel } from "../types/resourcesModel";

export default async function getResourceForCatalogue(
  deliverableId: string
): Promise<ResourcesModel[]> {
  try {
    const url =
      RESOURCE_CATALOGUE_ENDPOINTS.GET_RESOURCE_CATALOGUE_FOR_DELIVERABLE.replace(
        "{deliverableId}",
        deliverableId
      );

    const response: AxiosResponse<ResourcesModel[]> = await apiRequests.get(url);
    const { data, status } = response;

    if (status >= 200 && status < 300) {
      return data;
    }

    throw new Error(`Unknown error occurred with status code ${response.status}`);
  } catch (e) {
    // @todo Handle Error Below
    const error = e as AxiosError;
    switch (error.response?.status) {
      case 400:
        throw new Error("400: Bad Request");
      case 401:
        throw new Error("401: Unauthorized");
      case 404:
        throw new Error("404: Not Found");
      default:
        throw new Error(
          `Unknown error occurred with status code ${error.response?.status}`
        );
    }
  }
}

import { AxiosError, AxiosResponse } from "axios";

import { SCENARIOS_API_ENDPOINTS } from "@/features/outcomeOverview/api/endpoints";
import {
  LegacyAllowanceContingencyModel,
  LegacyPostReserveModel,
  OverviewTableModel,
} from "@/features/outcomeOverview/types/types";
import apiRequests from "@/shared/config/axiosConfig";
import { WBSEntryModel } from "@/shared/types/projectWBSModel";

// Define the type for the API response data
type ApiResponseData = {
  message?: string;
  statusCode?: number;
};

export default async function editOverviewTableService(
  OverviewTableRow: OverviewTableModel,
  wbsEntry: WBSEntryModel
): Promise<OverviewTableModel | ApiResponseData> {
  try {
    let url;
    let response: AxiosResponse<ApiResponseData>;
    if (OverviewTableRow.name === "Reserve") {
      url = SCENARIOS_API_ENDPOINTS.POST_SCENARIO_RESERVES.replace(
        "{wbsEntryId}",
        wbsEntry.projectId
      );
      const updateReserve: LegacyPostReserveModel = {
        managementReserve: OverviewTableRow.cost,
        managementReserveDuration: OverviewTableRow.duration,
        managementReserveCo2Emission: OverviewTableRow.emissions,
        scenarioType: "transactional",
      };
      response = await apiRequests.put(url, updateReserve);
    } else {
      url = SCENARIOS_API_ENDPOINTS.PATCH_SCENARIO_ALLOWANCES_CONTINGENCY.replace(
        "{wbsEntryId}",
        wbsEntry.id
      );
      let updateAllowanceContingency: LegacyAllowanceContingencyModel;

      if (OverviewTableRow.name === "Allowance") {
        updateAllowanceContingency = {
          executionContingencyCo2Emission: wbsEntry.contingencyForEmission,
          scopeAllowance: OverviewTableRow.cost,
          scopeAllowanceCo2Emission: OverviewTableRow.emissions,
          scopeAllowanceDuration: OverviewTableRow.duration,
        };
      } else {
        updateAllowanceContingency = {
          executionContingencyCo2Emission: OverviewTableRow.emissions,
          scopeAllowance: wbsEntry.allowanceForCost,
          scopeAllowanceCo2Emission: wbsEntry.allowanceForEmission,
          scopeAllowanceDuration: wbsEntry.allowanceForDuration,
        };
      }
      response = await apiRequests.patch(url, updateAllowanceContingency);
    }

    const { data, status } = response;

    if (status === 200 || status === 202) {
      return data as OverviewTableModel;
    }

    if (status === 204) {
      // Handle 204 status as successful
      return (
        (data as OverviewTableModel) ||
        ({ message: "No content", statusCode: 204 } as ApiResponseData)
      );
    }

    throw new Error(`Unknown error occurred with status code ${response.status}`);
  } catch (e) {
    // @todo Handle Error Below
    const error = e as AxiosError;
    switch (error.response?.status) {
      case 400:
        throw new Error("400: Bad Request");
      case 401:
        throw new Error("401: Unauthorized");
      case 404:
        throw new Error("404: Not Found");
      default:
        throw new Error(
          `Unknown error occurred with status code ${error.response?.status}`
        );
    }
  }
}

/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison -- many errors not worth fixing since this feature needs to be refactored */
import { Typography, useTheme } from "@mui/material";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import { MRT_ColumnDef } from "material-react-table";
import { useMemo } from "react";
import { Link } from "react-router-dom";

import { EditConceptModel } from "@/features/portfolio/models/editConceptModel";
import { RNumberInputLabel, RNumberLabel } from "@/shared/components/inputs";
import { X_LARGE_AMOUNT_RANGE } from "@/shared/constants";
import LifeCycleStage from "@/shared/enums/lifeCycleStage";
import ProjectRegion from "@/shared/enums/projectRegion";
import Risk, { getRiskColorFromIndex } from "@/shared/enums/risk";
import ProjectScenarios from "@/shared/enums/scenarios";
import {
  // formatCurrencyNoDecimals,
  getTextColorTemp,
  formatDate,
  // formatEmissions,
  // formatDays,
} from "@/shared/utils";

import determineIfIsProject, { PortfolioRowModel } from "../../models/portfolioModel";

// @check Function to get background color of lifecycle stage
const getBackgroundColor = (cellValue: number) => {
  switch (cellValue) {
    case 0:
      return "var(--ecerto-cool-grey-dark)";
    case 1:
      return "var(--ecerto-pantone-876)";
    case 2:
      return "var(--accent-color-light)";
    case 3:
      return "var(--primary-color)";
    default:
      return "var(--ecerto-cool-grey-dark)";
  }
};

const chipsOptions = {
  py: "0.85rem !important",
};
type HandleSaveCellType = (
  conceptToUpdate: EditConceptModel,
  name: string,
  value: number
) => Promise<void>;

export default function PortfolioColumns(handleSaveCell: HandleSaveCellType) {
  const theme = useTheme();
  return useMemo<MRT_ColumnDef<PortfolioRowModel>[]>(
    () => [
      {
        accessorKey: "region",
        enableEditing: false,
        header: "Region",
        minSize: 80,
        size: 150,
        maxSize: 150,
        muiTableBodyCellProps: {
          align: "left",
        },
        accessorFn: (row) => `${ProjectRegion[row.region]}`,
        Cell: ({ row }) => {
          const regionIndex = determineIfIsProject(row.original)
            ? row.original.region
            : row.getParentRow()?.original.region;
          return (
            <Typography>
              {regionIndex !== undefined ? `${ProjectRegion[regionIndex]}` : ""}
            </Typography>
          );
        },
      },
      {
        accessorKey: "programmeName",
        enableEditing: false,
        header: "Programme Name",
        minSize: 90,
        size: 90,
        maxSize: 100,
        muiTableBodyCellProps: {
          align: "left",
        },
        Cell: ({ row }) => {
          // Show project's programme name for concept children rows, too
          return (
            <Typography>
              {`${
                determineIfIsProject(row.original)
                  ? row.original.programmeName
                  : row.getParentRow()?.original.programmeName
              }`}
            </Typography>
          );
        },
      },
      {
        accessorKey: "name",
        enableEditing: false,
        enablePinning: false,
        header: "Project Name",
        muiTableBodyCellProps: {
          align: "left",
        },
        Cell: ({ row }) => {
          const { original } = row;

          // Extract the original ID for navigation, especially for subrows
          // had to do this since for subrows lv3, the ID is in the format of 'id - name', which is needed
          // to ensure the rows disappear (since the ID is unique)
          const originalId =
            original.type === 3 ? original.id.split(" - ")[0] : original.id;

          // Check if the row is a subrow (type 3)
          if (original.type === 3) {
            let chipLabel = "";
            if (original.selectedScenario < 1) {
              chipLabel = "LITE";
            } else if (original.selectedScenario >= 1 && original.selectedScenario <= 2) {
              chipLabel = "PRO";
            } else if (original.selectedScenario >= 3 && original.selectedScenario <= 6) {
              chipLabel = "ENTERPRISE";
            }

            return (
              <div style={{ display: "flex" }}>
                <Typography color="GrayText" fontSize="inherit">
                  {`${row.original.name}`}
                </Typography>

                <Chip
                  label={chipLabel}
                  size="small"
                  sx={{
                    backgroundColor:
                      chipLabel === "LITE"
                        ? "var(--ecerto-pantone-663)"
                        : theme.palette.primary.main,
                    color:
                      chipLabel === "LITE"
                        ? "var(--primary-color)"
                        : theme.palette.primary.contrastText,
                    height: "20px",
                    fontSize: "0.6rem",
                    marginLeft: "auto",
                    fontWeight: "bold",
                    borderRadius: "3px",
                    opacity: chipLabel === "LITE" ? "0.9" : "0.5",
                  }}
                />
              </div>
            );
          }

          // Default rendering for other rows, including 'Transactional' subrows
          return (
            <div style={{ fontWeight: "600", marginRight: "1rem" }}>
              <Link
                to={`/strategy-definition/${
                  determineIfIsProject(row.original)
                    ? row.original.selectedDevelopmentOptionId
                    : originalId
                }${row.original.type === 3 ? `/project-scenarios` : "/project-scope"}`}>
                <Typography variant="subtitle1">{`${row.original.name}`}</Typography>
              </Link>
            </div>
          );
        },
      },
      {
        accessorKey: "lifeCycleStage",
        enableEditing: false,
        header: "Life Cycle Stage",
        size: 40,
        accessorFn: (row) => `${LifeCycleStage[row.lifeCycleStage]}`,
        Cell: ({ row }) => {
          const lifeCycleStageValue = row.original.lifeCycleStage;

          if (lifeCycleStageValue > 3) return null;

          // Use the lifeCycleStage from selected Concept in projects, show own for concepts
          return (
            <Chip
              label={<Typography>{LifeCycleStage[lifeCycleStageValue]}</Typography>}
              size="medium"
              sx={{
                width: "90px",
                py: chipsOptions.py,
                background: getBackgroundColor(lifeCycleStageValue),
                color: "white",
              }}
            />
          );
        },
      },
      {
        accessorKey: "selectedDevelopmentOptionName",
        enableEditing: false,
        header: "Selected Concept",
        minSize: 0,
        size: 0,
        maxSize: 0,
        muiTableBodyCellProps: {
          align: "left",
        },
        Cell: ({ row }) => (
          <Typography>{`${row.original.selectedDevelopmentOptionName || ""}`}</Typography>
        ),
      },
      {
        accessorKey: "selectedScenario",
        enableEditing: false,
        header: "Selected Scenario",
        size: 0,
        Cell: ({ row }) => {
          // Use the selectedScenario from selectedDevelopmentOption for parent projects, show own for non-parents
          let selectedScenarioValue;
          if (row.original.type <= 2) {
            selectedScenarioValue = row.original.selectedScenario;
          } else if (row.original.type === 3) {
            selectedScenarioValue = ProjectScenarios[row.original.selectedScenario];
          }

          return (
            <Chip
              label={<Typography>{selectedScenarioValue}</Typography>}
              size="medium"
              variant="outlined"
              sx={{
                width: "130px",
                my: 0.5,
                border: selectedScenarioValue ? "1px solid" : "transparent",
                py: chipsOptions.py,
                background: selectedScenarioValue ? "transparent" : "transparent",
                color: selectedScenarioValue ? "text.primary" : "transparent",
              }}
            />
          );
        },
      },
      {
        accessorKey: "riskLevel",
        enableEditing: false,
        header: "Risk Level",
        minSize: 40,
        size: 40,
        maxSize: 80,
        accessorFn: (row) => `${Risk[row.riskLevel]}`,
        Cell: ({ row }) => {
          if (row.original.riskLevel > 4) return null;

          // Use the riskLevel from selectedDevelopmentOption for parent projects, show own for non-parents
          const riskIndex =
            determineIfIsProject(row.original) && row.original.selectedDevelopmentOption
              ? row.original.selectedDevelopmentOption.riskLevel
              : row.original.riskLevel;

          const riskColor = getRiskColorFromIndex(riskIndex);
          const textColor = getTextColorTemp(riskColor);
          const riskName = Risk[riskIndex];

          return (
            <Tooltip
              title={riskName}
              color="primary"
              sx={{
                background: riskColor,
              }}>
              <Chip
                sx={{
                  width: "50%",
                  height: "10px",
                  background: riskColor,
                  color: textColor,
                }}
              />
            </Tooltip>
          );
        },
      },
      {
        accessorKey: "projectCostBaseline", // This name is wrong, should be changed to projectEstimate. Needs formatting from API.
        enableEditing: false,
        header: "Estimate",
        size: 125,
        muiTableBodyCellProps: {
          align: "right",
        },
        Cell: ({ row }) => (
          <RNumberLabel category="currency" value={row.original.projectCostBaseline} />
        ),
        muiTableHeadCellProps: () => ({
          style: { backgroundColor: "var(--ecerto-green)" },
        }),
      },
      {
        accessorKey: "projectScopeAllowance", // This name is wrong, should be changed to projectAllowance. Needs formatting from API.
        enableEditing: false,
        header: "Allowance",
        size: 60,
        muiTableBodyCellProps: {
          align: "right",
        },
        Cell: ({ row }) => (
          <RNumberLabel category="currency" value={row.original.projectScopeAllowance} />
        ),
      },
      {
        accessorKey: "projectControlEstimate", // This name is wrong, should be changed to projectBaseline. Needs formatting from API.
        enableEditing: false,
        header: "Baseline",
        size: 60,
        muiTableBodyCellProps: {
          align: "right",
        },
        Cell: ({ row }) => (
          <RNumberLabel category="currency" value={row.original.projectControlEstimate} />
        ),
        muiTableHeadCellProps: () => ({
          style: { backgroundColor: "var(--ecerto-green)" },
        }),
      },
      {
        accessorKey: "projectExecutionContingency", // This name is wrong, should be changed to projectContingency. Needs formatting from API.
        enableEditing: false,
        header: "Contingency",
        size: 60,
        muiTableBodyCellProps: {
          align: "right",
        },
        Cell: ({ row }) => (
          <RNumberLabel
            category="currency"
            value={row.original.projectExecutionContingency}
          />
        ),
      },
      {
        accessorKey: "budget",
        enableEditing: false,
        header: "Budget",
        size: 60,
        muiTableBodyCellProps: {
          align: "right",
        },
        muiTableHeadCellProps: () => ({
          style: {
            backgroundColor: "var(--ecerto-green)",
          },
        }),
        Cell: ({ row }) => (
          <RNumberLabel category="currency" value={row.original.budget} />
        ),
      },
      {
        accessorKey: "projectManagementReserve",
        enableEditing: false,
        header: "Reserve",
        size: 60,
        muiTableBodyCellProps: {
          align: "right",
        },
        Cell: ({ row }) => (
          <RNumberLabel
            category="currency"
            value={row.original.projectManagementReserve}
          />
        ),
      },
      {
        accessorKey: "estimatedCost", // Currently being used as Budget and Project Cost depending on level, but these should be separated.
        enableEditing: false,
        header: "Project Cost",
        size: 60,
        muiTableBodyCellProps: {
          align: "right",
        },
        Cell: ({ row }) => (
          <RNumberLabel category="currency" value={row.original.estimatedCost} />
        ),
        muiTableHeadCellProps: () => ({
          style: { backgroundColor: "var(--ecerto-green)" },
        }),
      },
      {
        accessorKey: "estimatedStart",
        enableEditing: false,
        header: "Start",
        size: 60,
        muiTableBodyCellProps: {
          align: "right",
        },
        //@todo We should be using a reusable date component instead
        Cell: ({ row }) => (
          <Typography>{formatDate(row.original.estimatedStart)}</Typography>
        ),
      },
      {
        accessorKey: "estimatedEnd",
        enableEditing: false,
        header: "End",
        size: 60,
        muiTableBodyCellProps: {
          align: "center",
        },
        //@todo We should be using a reusable date component instead
        Cell: ({ row }) => (
          <Typography>{formatDate(row.original.estimatedEnd)}</Typography>
        ),
      },
      {
        accessorKey: "projectDurationBaseline",
        enableEditing: false,
        header: "Duration",
        size: 60,
        muiTableBodyCellProps: {
          align: "right",
        },
        Cell: ({ row }) => (
          <RNumberLabel
            value={row.original.projectDurationBaseline ?? 0}
            category="duration"
          />
        ),
        muiTableHeadCellProps: () => ({
          style: { backgroundColor: "var(--ecerto-green)" },
        }),
      },
      {
        accessorKey: "cO2Emissions",
        enableEditing: false,
        header: "Emissions",
        size: 60,
        muiTableBodyCellProps: {
          align: "right",
        },
        Cell: ({ row }) => (
          <RNumberLabel value={row.original.cO2Emissions ?? 0} category="emissions" />
        ),
        muiTableHeadCellProps: () => ({
          style: { backgroundColor: "var(--ecerto-green)" },
        }),
      },
      {
        accessorKey: "netPresentValue",
        header: "NPV",
        size: 160,
        muiTableBodyCellProps: {
          align: "right",
        },
        Cell: ({ row }) => {
          const isEditable = row.original.type === 2;
          const cellValue = row.original.netPresentValue;
          const cellToUpdate = "netPresentValue";
          const conceptToUpdate = row.original;

          return isEditable ? (
            <RNumberInputLabel
              category="currency"
              value={cellValue}
              range={X_LARGE_AMOUNT_RANGE}
              onInputChange={(newValue) => {
                void handleSaveCell(conceptToUpdate, cellToUpdate, newValue);
              }}
            />
          ) : (
            <RNumberLabel value={cellValue} category="currency" />
          );
        },
      },

      {
        accessorKey: "internalRateOfReturn",
        enableEditing: (row) => row.original.type === 2,
        header: "IRR",
        size: 80,

        muiTableBodyCellProps: {
          align: "right",
        },
        Cell: ({ row }) => {
          const isEditable = row.original.type === 2;
          const cellValue = row.original.internalRateOfReturn;
          const cellToUpdate = "internalRateOfReturn";
          const conceptToUpdate = row.original;

          return isEditable ? (
            <RNumberInputLabel
              category="percentage"
              value={cellValue}
              onInputChange={(newValue) => {
                void handleSaveCell(conceptToUpdate, cellToUpdate, newValue);
              }}
            />
          ) : (
            <RNumberLabel value={cellValue} category="percentage" />
          );
        },
      },
    ],
    [theme.palette.primary.contrastText, theme.palette.primary.main, handleSaveCell]
  );
}

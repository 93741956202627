import { ViewMode } from "@ecerto/gantt-chart";
import styled from "@emotion/styled";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import {
  TimeFrameMenuStyle,
  TimeFrameSelectStyle,
} from "@/features/projectSchedule/styles/styles";
import { TimeFrameTypes } from "@/features/projectSchedule/types/types";

const StyledMenuItem = styled(MenuItem)(() => ({
  borderRadius: "3px",
}));

export default function TimeFrame({ view, onTimeFrameChange }: TimeFrameTypes) {
  return (
    <FormControl title="Change Time Frame">
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        variant="outlined"
        value={view}
        aria-label="Time Frame"
        onChange={(event) => onTimeFrameChange("view", event.target.value as ViewMode)}
        MenuProps={TimeFrameMenuStyle()}
        sx={TimeFrameSelectStyle}>
        <StyledMenuItem value="Day">Day</StyledMenuItem>
        <StyledMenuItem value="Week">Week</StyledMenuItem>
        <StyledMenuItem value="Month">Month</StyledMenuItem>
        <StyledMenuItem value="QuarterYear">Quarters</StyledMenuItem>
        <StyledMenuItem value="Year">Year</StyledMenuItem>
      </Select>
    </FormControl>
  );
}

import Chip from "@mui/material/Chip";

type RChipProps = {
  label?: "LITE" | "PRO" | "ENTERPRISE";
  size?: "small" | "medium";
  variant?: "outlined" | "filled";
  style?: object;
  color?: string;
  backgroundColor?: string;
};

export default function RChip({
  label,
  size = "small",
  variant,
  style,
  color,
  backgroundColor,
}: RChipProps) {
  const getStyles = () => {
    if (backgroundColor ?? color) {
      return {
        backgroundColor: backgroundColor ?? "inherit",
        color: color ?? "inherit",
      };
    }

    switch (label) {
      case "LITE":
        return {
          backgroundColor: "var(--ecerto-pantone-663)",
          color: "var(--primary-color)",
        };
      case "PRO":
      case "ENTERPRISE":
        return {
          backgroundColor: "var(--primary-color)",
          color: "var(--background-color)",
        };
      default:
        return {};
    }
  };

  return (
    <Chip
      label={label}
      variant={variant}
      size={size}
      sx={{
        height: "1.75rem",
        fontSize: "0.75rem",
        fontWeight: "bold",
        borderRadius: "0.25rem",
        padding: "0 0.25rem",
        margin: "0 0.5rem",
        ...getStyles(),
        ...style,
      }}
    />
  );
}

import { ViewMode } from "@ecerto/gantt-chart";

import { DisplayOptionsType } from "@/features/projectSchedule/types/types";

// Default display options
const defaultDisplayOptions: DisplayOptionsType = {
  view: ViewMode.Year,
  sliderHeight: 5,
  sliderWidth: 5,
  isTimeLineView: false,
  // ...other properties
};
export default defaultDisplayOptions;

import pluralize from "pluralize";

import { WBSEntryModel } from "@/shared/context/projectWBS/projectWBSModel";
import { UnitsAbbreviations } from "@/shared/enums/units";
import { ParameterModels } from "@/shared/types/parameterModels";

import { OverviewItemsModel } from "../model/baselineOverviewModels";

const getCustomUnit = (parametricOutcome: ParameterModels): string => {
  const unitAbbreviation = UnitsAbbreviations.get(parametricOutcome.unit) ?? "";
  const singularUnit = pluralize.singular(unitAbbreviation);
  return singularUnit;
};

export default function updateBaselineOverviewSchema(
  overviewItems: OverviewItemsModel[],
  entry: WBSEntryModel | null,
  parametricOutcome: ParameterModels
): OverviewItemsModel[] {
  if (entry === null) {
    return overviewItems;
  }

  const filterOverviewItems = overviewItems;

  return filterOverviewItems.map((item) => {
    switch (item.title) {
      case "Cost / Unit":
        return {
          ...item,
          customUnit: getCustomUnit(parametricOutcome),
          amount: entry.estimateForCost / parametricOutcome.quantity,
        };
      case "Standby Cost / Day":
        return {
          ...item,
          amount: entry.standbyCost,
        };
      case "Cost":
        return {
          ...item,
          amount: entry.estimateForCost,
        };
      case "Duration":
        return {
          ...item,
          amount: entry.estimateForDuration,
        };
      case "Emissions":
        return {
          ...item,
          amount: entry.estimateForEmissions,
        };
      case "Impact: Cost":
        return {
          ...item,
          amount: entry.contingencyForCost,
        };
      case "Impact: Duration":
        return {
          ...item,
          amount: entry.contingencyForDuration,
        };
      case "Impact: Emissions":
        return {
          ...item,
          amount: entry.contingencyForEmission,
        };
      default:
        return item;
    }
  });
}

import { AxiosError, AxiosResponse } from "axios";

import { PROJECTS_API_ENDPOINTS } from "@/features/portfolio/api/endpoints";
import { PortfolioRowModel } from "@/features/portfolio/models/portfolioModel";
import apiRequests from "@/shared/config/axiosConfig";

import { AddProjectModel } from "../models/addProjectModel";

export default async function addProject(
  project: AddProjectModel
): Promise<PortfolioRowModel> {
  // @important appending type, which is always 1 for projects
  const projectWithType = { ...project, type: 1 };

  try {
    const url = PROJECTS_API_ENDPOINTS.GET_PROJECTS;

    const response: AxiosResponse<PortfolioRowModel> = await apiRequests.post(
      url,
      projectWithType
    );

    const { data, status } = response;

    if (status >= 200 && status < 300) {
      return data;
    }

    throw new Error(`Unexpected status code: ${status}`);
  } catch (e) {
    const error = e as AxiosError;
    switch (error.response?.status) {
      case 400:
        throw new Error("400: Bad Request");
      case 401:
        throw new Error("401: Unauthorized");
      case 404:
        throw new Error("404: Not Found");
      default:
        throw new Error(
          `Unknown error occurred with status code ${error.response?.status}`
        );
    }
  }
}

import { ViewMode } from "@ecerto/gantt-chart";
import dayjs from "dayjs";

import defaultDisplayOptions from "@/features/projectSchedule/components/displayOptions/defaultOptions";
import { DisplayOptionsType } from "@/features/projectSchedule/types/types";
import { WBSEntryModel } from "@/shared/context/projectWBS/projectWBSModel";
import { IScheduleItems } from "@/shared/types/scheduleItems";

/**
 * This function updates each schedule item's expanded state. If an item is a root node or its id is in the expanded nodes list, hideChildren is set to false, making its children visible. Otherwise, the item remains unchanged.
 *
 * @param {IScheduleItems[]} items - The list of schedule items to update.
 * @param {string[]} expandedNodes - The list of ids of the nodes that should be expanded.
 *
 * @returns {IScheduleItems[]} The updated list of schedule items.
 */
export const updateExpandedState = (items: IScheduleItems[], expandedNodes: string[]) => {
  return items.map((item) => {
    if (item.level === 0 || expandedNodes.includes(item.id)) {
      return { ...item, hideChildren: false };
    }
    return item;
  });
};
// @todo: when not table but dragndrop of endDate we get a day before why?

/**
 * This function updates a WBS entry's start date and duration after a drag-and-drop operation. It converts the start and end dates to Day.js objects, calculates the new duration, and formats the start date to a "YYYY-MM-DD" string. It then returns a new WBS entry with the updated start date and duration.
 *
 *  @param {IScheduleItems} wbsEntry - The WBS entry to be updated.
 *
 * @returns {IScheduleItems} The updated WBS entry.
 */
export const applyDragDropUpdate = (wbsEntry: IScheduleItems): IScheduleItems => {
  const startDate = dayjs(wbsEntry.start);
  const endDate = dayjs(wbsEntry.end);
  const newDuration = endDate.diff(startDate, "day");
  // Format the date to "YYYY-MM-DD" to set time to midnight UTC.
  // This prevents time zone differences from shifting the date to the previous day.
  const dateStr = startDate.format("YYYY-MM-DD");
  const newStartDate = new Date(dateStr);
  return { ...wbsEntry, duration: newDuration, start: newStartDate };
};

/**
 * This function creates a payload for updating a Work Breakdown Structure (WBS) entry with new schedule item data.
 *
 * @param {WBSEntryModel} current - The current WBS entry to be updated.
 * @param {IScheduleItems} updated - The new schedule item data.
 *
 * @returns {object} The payload for updating the WBS entry.
 */
export const createPayload = (current: WBSEntryModel, updated: IScheduleItems) => ({
  ...current,
  start: new Date(updated.start),
  outcomeDurationDays: Number(updated.duration),
  productiveTimeDays: Number(updated.duration),
});

/**
 * This function scales a slider value (1-10) to a column size within a given range.
 * It calculates a step size based on the range of column sizes and slider values.
 * The column size is then computed using the step size, slider value, and minimum column size.
 */
const mapSliderToColumnSize = (
  sliderValue: number,
  minColumnSize: number,
  maxColumnSize: number
) => {
  const minSlider = 1; // Minimum value of the slider
  const maxSlider = 10; // Maximum value of the slider

  // Calculate the step size
  const stepSize = (maxColumnSize - minColumnSize) / (maxSlider - minSlider);

  // Calculate the column size
  const columnSize = minColumnSize + (sliderValue - minSlider) * stepSize;

  return columnSize;
};

/**
 * This function calls mapSliderToColumnSize with the slider value and a column width range based on the column type. It throws an error for invalid column types.
 *
 * @param {number} sliderValue The current value of the slider controlling the width of the columns.
 * @param {ViewMode} columnType The type of the column. Can be "Day", "Week", "Month", "QuarterYear", or "Year".
 *
 * @returns {number} The calculated width of the column.
 */
export const getColumnWidth = (sliderValue: number, columnType: ViewMode) => {
  switch (columnType) {
    case ViewMode.Day:
      return mapSliderToColumnSize(sliderValue, 29, 50);
    case ViewMode.Week:
      return mapSliderToColumnSize(sliderValue, 50, 135);
    case ViewMode.Month:
      return mapSliderToColumnSize(sliderValue, 85, 170);
    case ViewMode.QuarterYear:
      return mapSliderToColumnSize(sliderValue, 85, 170);
    case ViewMode.Year:
      return mapSliderToColumnSize(sliderValue, 90, 500);
    default:
      throw new Error(`Invalid column type: ${columnType}`);
  }
};

/**
 * The function uses a base height of 600 for the schedule. For each unit increase in the slider height above 1,
 * it increases the schedule height by 50. This allows the user to control the height of the schedule using the slider.
 *
 * @param {number} sliderHeight The current value of the slider controlling the height of the schedule.
 *
 * @returns {number} The calculated height of the schedule.
 */
export const getScheduleHeight = (sliderHeight: number) => {
  const ganttHeight = 600 + (sliderHeight - 1) * 50;
  return ganttHeight;
};

/**
 * This function calculates the left padding based on the provided level.
 *
 * @param {number | undefined} level The level for which to calculate the padding.
 * This is typically a value between 0 and 3, but it can also be undefined.
 *
 * @returns {number} The calculated left padding. The function returns 10 for levels 0 and 2,
 * 0 for level 1, 60 for level 3, and 0 for all other levels or if the level is undefined.
 */
export const calculatePaddingLeft = (level: number | undefined): number => {
  switch (level) {
    case 0:
    case 2:
      return 10;
    case 1:
      return 0;
    case 3:
      return 60;
    default:
      return 0;
  }
};

/**
 * This function formats a Date object to a string in the "YYYY-MM-DD" format.
 * It's designed to avoid timezone issues by discarding the time portion of the Date.
 *
 * @param {Date} startDate The Date object to be formatted.
 *
 * @returns {string} The formatted date string in the "YYYY-MM-DD" format.
 */
export const formatDateToAvoidTimezoneIssues = (startDate: Date): string => {
  const date = dayjs(startDate);
  const dateStr = date.format("YYYY-MM-DD");
  return dateStr;
};

/**
 * This function updates a Work Breakdown Structure (WBS) entry with a new start date and duration.
 *
 * @param {IScheduleItems} scheduleEntry - The WBS entry to be updated. This is an object that represents a task or activity in the project schedule.
 * @param {string} startDate - The new start date for the task or activity. This should be a string in the "YYYY-MM-DD" format.
 * @param {number} newDuration - The new duration for the task or activity. This should be a number that represents the duration in days.
 *
 * @returns {IScheduleItems} - The updated WBS entry. This is a new object that has the same properties as the original entry, but with the start date and duration updated to the new values.
 */
export const updateWBSEntry = (
  scheduleEntry: IScheduleItems,
  startDate: string,
  newDuration: number
): IScheduleItems => {
  return {
    ...scheduleEntry,
    start: new Date(startDate),
    duration: newDuration,
  };
};
/**
 * It retrieves the display options from the local storage.
 * If there are no saved options in local storage, it returns the default display options.
 * @returns {DisplayOptionsType} - The initialized display options.
 */
export const initializeDisplayOptions = (): DisplayOptionsType => {
  // Retrieve the display options from local storage
  const savedOptions = localStorage.getItem("displayOptions");

  // If there are saved options, parse them into an object.
  // If there are no saved options, return the default display options.
  if (savedOptions) {
    const parsedOptions = JSON.parse(savedOptions) as DisplayOptionsType;
    return parsedOptions;
  }
  return defaultDisplayOptions;
};

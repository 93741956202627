import { Paper } from "@mui/material";

import OverviewDetailCard from "@/features/outcomeOverview/components/OverviewDetailCard";
import OverviewTable from "@/features/outcomeOverview/components/table/OverviewTable";
import { OutcomeOverviewPaperStyle } from "@/features/outcomeOverview/styles/styles";
import { WBSEntryModel } from "@/shared/context/projectWBS/projectWBSModel";

export default function OutcomeOverview({ wbsEntry }: { wbsEntry: WBSEntryModel }) {
  return (
    <Paper elevation={4} sx={OutcomeOverviewPaperStyle}>
      <OverviewDetailCard wbsEntry={wbsEntry} />
      <OverviewTable wbsEntry={wbsEntry} />
    </Paper>
  );
}

import { Box } from "@mui/system";

import { ScheduleTableRowTypes } from "@/features/projectSchedule/types/types";

const getScheduleTableRowStyles = ({
  height,
  fontSize,
  fontFamily,
  fontColor,
  alignment,
  wbsEntryLevel,
}: ScheduleTableRowTypes) => {
  let backgroundColor = "#003057"; // default to blue

  if (wbsEntryLevel !== undefined) {
    switch (wbsEntryLevel) {
      case 0:
      case 1:
        backgroundColor = "rgb(255, 255, 255)";
        break;
      case 2:
        backgroundColor = "rgb(245, 245, 245)";
        break;
      case 3:
        // Level 3 tasks only have barBackgroundColor
        backgroundColor = "rgb(239, 239, 239)";
        break;
      default:
        backgroundColor = "rgb(255, 255, 255)";
    }
  }

  return {
    display: "flex",
    alignItems: alignment ?? "center",
    color: fontColor ?? "#003057",
    backgroundColor,
    fontFamily,
    fontSize,
    height,
  };
};

export default function ScheduleTableRow({ children, ...rest }: ScheduleTableRowTypes) {
  return <Box sx={getScheduleTableRowStyles(rest)}>{children}</Box>;
}

import { AxiosError, AxiosResponse } from "axios";

import { PROJECTS_API_ENDPOINTS } from "@/features/portfolio/api/endpoints";
import { PortfolioRowModel } from "@/features/portfolio/models/portfolioModel";
import apiRequests from "@/shared/config/axiosConfig";

import { EditProjectModel } from "../models/editProjectModel";

export default async function editProject(
  project: EditProjectModel
): Promise<PortfolioRowModel> {
  try {
    const url = PROJECTS_API_ENDPOINTS.GET_PROJECTS;
    const response: AxiosResponse<PortfolioRowModel> = await apiRequests.put(
      url,
      project
    );

    const { data, status } = response;

    if (status === 200) {
      return data;
    }

    if (status === 204) {
      // Handle 204 status as successful
      return data || { message: "No content", statusCode: 204 };
    }

    throw new Error(`Unknown error occurred with status code ${status}`);
  } catch (e) {
    // @todo Handle Error Below
    const error = e as AxiosError;
    switch (error.response?.status) {
      case 400:
        throw new Error("400: Bad Request");
      case 401:
        throw new Error("401: Unauthorized");
      case 404:
        throw new Error("404: Not Found");
      default:
        throw new Error(
          `Unknown error occurred with status code ${error.response?.status}`
        );
    }
  }
}

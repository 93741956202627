import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { FormikProps } from "formik";
import { useRef, useState } from "react";

import ReusableDialog from "@/shared/components/ReusableDialog";
import useProjectWBSContext from "@/shared/context/projectWBS/useProjectWBSContext";

import AddRiskForm from "../forms/AddRiskForm";
import { RiskBaseModel } from "../types/riskModels";

export type AddRiskProps = {
  refetchRisk: () => void;
};

export default function AddRisk({ refetchRisk }: AddRiskProps) {
  const [isModalOpen, setModalOpen] = useState(false);
  const addFormikRef = useRef<FormikProps<RiskBaseModel>>(null);
  const { refetchProjectWBS } = useProjectWBSContext();

  const addRisk = async () => {
    if (addFormikRef.current) {
      await addFormikRef.current.submitForm();
    }
    refetchRisk();
    refetchProjectWBS(); // Trigger refetching of projects
  };
  const handleSubmitForm = () => {
    void addRisk();
    handleClose();
  };

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        backgroundColor: "white",
        borderRadius: "4px",
        marginTop: "24px",
      }}>
      <Button
        onClick={handleOpen}
        variant="outlined"
        size="large"
        sx={{
          marginTop: "10px",
          marginBottom: "14px",
          marginLeft: "10px",
          fontWeight: "bold",
        }}>
        Add Risk
      </Button>
      <ReusableDialog
        isOpen={isModalOpen}
        title="Add Risk"
        confirmButtonCallback={handleSubmitForm}
        cancelButtonCallback={handleClose}
        confirmButtonText="Create"
        confirmButtonType="primary"
        size="medium">
        <AddRiskForm innerRef={addFormikRef} />
      </ReusableDialog>
    </Box>
  );
}

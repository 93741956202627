import { AxiosError } from "axios";

import { DELIVERABLES_API_ENDPOINTS } from "@/features/projectScope/api/endpoints";
import apiRequests from "@/shared/config/axiosConfig";
import { WBSEntryViewModel } from "@/shared/context/projectWBS/projectWBSModel";

export default async function editOrderByDragDrop(
  wbsEntries: WBSEntryViewModel[],
  projectId: string
) {
  try {
    const url = DELIVERABLES_API_ENDPOINTS.UPDATE_DELIVERABLE_ORDER.replace(
      "{projectid}",
      projectId
    );
    const response = await apiRequests.put(url, wbsEntries);

    if (response.status >= 200 && response.status < 300) {
      return null;
    }

    throw new Error(`Unknown error occurred with status code ${response.status}`);
  } catch (e) {
    // @todo Handle Error Below
    const error = e as AxiosError;
    switch (error.response?.status) {
      case 400:
        throw new Error("400: Bad Request");
      case 401:
        throw new Error("401: Unauthorized");
      case 404:
        throw new Error("404: Not Found");
      default:
        throw new Error(
          `Unknown error occurred with status code ${error.response?.status}`
        );
    }
  }
}

import { useTheme, Grid } from "@mui/material";

import {
  getOverviewItemStyles,
  OverviewItemsModel,
} from "@/shared/components/baselineOverview";
import { RNumberLabel } from "@/shared/components/inputs";

type OverviewItemProps = {
  item: OverviewItemsModel;
  type?: string;
};
const getWidth = (title: string): string => {
  if (title.includes("Cost / Unit") || title.includes("Standby Cost / Day")) {
    return "17rem";
  }
  if (title.includes("Cost")) {
    return "11rem";
  }
  if (title.includes("Duration")) {
    return "8rem";
  }
  if (title.includes("Emissions")) {
    return "9rem";
  }
  return "15rem";
};

export default function OverviewItem({ item, type }: OverviewItemProps) {
  const theme = useTheme();
  const { typographyAmountStyle } = getOverviewItemStyles(theme);

  let colorDisplay = item.color;
  const itemWidth = getWidth(item.title);

  if (type === "estimate") {
    colorDisplay = "var(--ecerto-green)";
  } else if (type === "risk") {
    colorDisplay = "var(--ecerto-red)";
  }
  typographyAmountStyle.color = colorDisplay;

  return (
    <Grid
      item
      sx={{
        textAlign: "right",
        width: itemWidth,
      }}>
      {item && item.amount !== 0 ? (
        <RNumberLabel
          key={item.id}
          value={item.amount}
          category={item.category}
          customUnit={item.customUnit}
          typographyProps={typographyAmountStyle}
        />
      ) : (
        "" // Return empty string if amount is 0
      )}
    </Grid>
  );
}

import { forwardRef } from "react";
import { NumericFormat, NumericFormatProps } from "react-number-format";

// Define the CustomProps type
export type CustomProps = {
  onChange: (event: { target: { value: number } }) => void;
  validRange: { min: number; max: number };
};

// Define and export the NumericFormatCustom component
const NumberInputFormatter = forwardRef<NumericFormatProps, CustomProps>((props, ref) => {
  const { onChange, validRange, ...other } = props;

  // Checks if the input value is within the specified range. Prevents onChange from triggering if the value is out of bounds.
  const isAllowed = (values: { floatValue?: number }) => {
    if (validRange) {
      const { floatValue } = values;
      return (
        floatValue === undefined ||
        (floatValue >= validRange.min && floatValue <= validRange.max)
      );
    }
    return true;
  };

  return (
    <NumericFormat
      {...other} // other contain props passed from parent e.g.(allowNegative, decimalScale)
      getInputRef={ref}
      thousandSeparator
      fixedDecimalScale
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.floatValue ?? 0,
          },
        });
      }}
      isAllowed={isAllowed}
    />
  );
});
// Set the display name for the component to avoid the ESLint warning
NumberInputFormatter.displayName = "NumberInputFormatter";
// Default export
export default NumberInputFormatter;

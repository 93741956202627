import editProject from "@/features/portfolio/api/editProjectService";
import { AddConceptModel } from "@/features/portfolio/models/addConceptModel";
import { EditProjectModel } from "@/features/portfolio/models/editProjectModel";

import { Concept, PortfolioRowModel, Project } from "../models/portfolioModel";

export const updateSelectedConcept = async (
  savedProject: PortfolioRowModel,
  savedConcept: AddConceptModel
): Promise<PortfolioRowModel | null> => {
  if (savedProject.selectedDevelopmentOptionId === null) {
    const selectedConcept: EditProjectModel = {
      id: savedProject.id,
      name: savedProject.name,
      programmeName: savedProject.programmeName,
      region: savedProject.region,
      riskLevel: savedConcept.riskLevel,
      lifeCycleStage: savedConcept.lifeCycleStage,
      selectedDevelopmentOptionId: savedConcept?.id ?? "",
      type: savedProject.type,
    };
    try {
      const editProjectResponse = await editProject(selectedConcept);
      return editProjectResponse;
    } catch (error: unknown) {
      if (error instanceof Error) {
        throw new Error(`Error updating selected concept: ${error.message}`);
      } else {
        throw new Error("Unknown error updating selected concept");
      }
    }
  }
  return null;
};

// Mapping function
export default function mapProjectsToPortfolioRowModels(
  projects: Project[]
): PortfolioRowModel[] {
  return projects.map((project) => {
    // Find the selected concept
    const selectedConcept = project.concepts.find(
      (concept) => concept.id === project.selectedConceptId
    );

    let conceptModels: PortfolioRowModel[] = [];

    try {
      // Map concepts to ConceptModels
      conceptModels = project.concepts.map(
        (concept): PortfolioRowModel => ({
          id: concept.id,
          name: concept.conceptName,
          type: concept.type,
          parentId: project.projectId,
          parentName: project.projectName,
          programmeName: project.programmeName,
          region: project.region,
          order: concept.order,
          projectCode: concept.projectCode,
          riskLevel: concept.riskLevel,
          lifeCycleStage: concept.lifeCycleStage,
          selectedScenario: concept.selectedScenario,
          projectCostBaseline: concept.estimate,
          projectScopeAllowance: concept.allowance,
          projectControlEstimate: concept.baseline,
          projectExecutionContingency: concept.contingency,
          projectManagementReserve: concept.reserve,
          estimatedCost: concept.projectCost,
          budget: concept.budget,
          estimatedStart: concept.estimatedStartDate,
          estimatedEnd: concept.estimatedEndDate,
          projectDurationBaseline: concept.projectDuration,
          netPresentValue: concept.netPresentValue,
          internalRateOfReturn: concept.internalRateOfReturn,
          cO2Emissions: concept.projectEmissions,
          children: [], // Assuming no nested concepts
          selectedDevelopmentOption: null,
          selectedDevelopmentOptionId: "",
          selectedDevelopmentOptionName: "",
        })
      );
    } catch (error: unknown) {
      if (error instanceof Error) {
        // Handle error appropriately
        throw new Error(`Error mapping concepts to models: ${error.message}`);
      } else {
        throw new Error("Unknown error mapping concepts to models");
      }
    }

    // Map project to PortfolioRowModel
    return {
      id: project.projectId,
      type: project.type,
      parentId: null,
      parentName: null,
      cO2Emissions: selectedConcept ? selectedConcept.projectEmissions : 0,
      order: project.order,
      projectCode: project.projectCode,
      selectedDevelopmentOption: selectedConcept
        ? conceptModels.find((concept) => concept.id === selectedConcept.id)
        : null,
      selectedDevelopmentOptionId: project.selectedConceptId,
      name: project.projectName,
      region: project.region,
      programmeName: project.programmeName,
      lifeCycleStage: selectedConcept ? selectedConcept.lifeCycleStage : 0,
      selectedDevelopmentOptionName: selectedConcept ? selectedConcept.conceptName : "",
      selectedScenario: selectedConcept ? selectedConcept.selectedScenario : 0,
      riskLevel: selectedConcept ? selectedConcept.riskLevel : 0,
      projectCostBaseline: selectedConcept ? selectedConcept.estimate : 0,
      projectScopeAllowance: selectedConcept ? selectedConcept.allowance : 0,
      projectControlEstimate: selectedConcept ? selectedConcept.baseline : 0,
      projectExecutionContingency: selectedConcept ? selectedConcept.contingency : 0,
      projectManagementReserve: selectedConcept ? selectedConcept.reserve : 0,
      estimatedCost: selectedConcept ? selectedConcept.projectCost : 0,
      estimatedStart: selectedConcept ? selectedConcept.estimatedStartDate : "",
      estimatedEnd: selectedConcept ? selectedConcept.estimatedEndDate : "",
      projectDurationBaseline: selectedConcept ? selectedConcept.projectDuration : 0,
      netPresentValue: selectedConcept ? selectedConcept.netPresentValue : 0,
      internalRateOfReturn: selectedConcept ? selectedConcept.internalRateOfReturn : 0,
      children: conceptModels,
      budget: selectedConcept ? selectedConcept.budget : 0,
    };
  });
}

export const sortPortfolioData = (
  portfolioData: PortfolioRowModel[]
): PortfolioRowModel[] => {
  return portfolioData
    .sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
    .map((project) => ({
      ...project,
      children: sortPortfolioData(project.children),
    }));
};

export const conceptToPortfolioRowModel = (
  concept: Concept,
  project: PortfolioRowModel
): PortfolioRowModel => {
  return {
    id: concept.id,
    type: concept.type,
    parentId: concept.parentId,
    parentName: project.name, // Assuming there's no direct mapping for parentName
    budget: concept.budget,
    cO2Emissions: concept.projectEmissions,
    order: concept.order,
    projectCode: concept.projectCode,
    selectedDevelopmentOption: null, // Assuming no direct mapping, needs further implementation if nested structure is required
    selectedDevelopmentOptionId: "", // Assuming there's no direct mapping, or it's not applicable in this context
    name: concept.conceptName,
    region: project.region, // Assuming there's no direct mapping for region, placeholder null
    programmeName: project.programmeName, // Assuming there's no direct mapping for programmeName, placeholder empty string
    lifeCycleStage: concept.lifeCycleStage,
    selectedDevelopmentOptionName: "", // Assuming no direct mapping, placeholder empty string
    selectedScenario: concept.selectedScenario,
    riskLevel: concept.riskLevel,
    projectCostBaseline: concept.estimate,
    projectScopeAllowance: concept.allowance,
    projectControlEstimate: concept.baseline,
    projectExecutionContingency: concept.contingency,
    projectManagementReserve: concept.reserve,
    estimatedCost: concept.projectCost,
    estimatedStart: concept.estimatedStartDate,
    estimatedEnd: concept.estimatedEndDate,
    projectDurationBaseline: concept.projectDuration,
    netPresentValue: concept.netPresentValue,
    internalRateOfReturn: concept.internalRateOfReturn,
    children: [], // Assuming no children initially, can be populated later if needed
  };
};

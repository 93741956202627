import { SystemStyleObject } from "@mui/system";

export const OutcomeOverviewPaperStyle = {
  display: "flex",
  borderRadius: "0.3rem",
  overflow: "hidden",
};

export const OverviewDetailCardStyle = {
  width: "40%",
  borderRight: "0.35rem solid var(--ecerto-breakdown-brown)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "4rem 1.875rem",
  textAlign: "center",
  color: "white",
  backgroundColor: "var(--primary-color)",
  "& .MuiTypography-subtitle1": {
    color: "#a7b4c2",
  },
};

export const OverviewDetailCardDateStyle = {
  display: "flex",
  justifyContent: "space-around",
};

export const OverviewTableStyle = {
  width: "60%",
  th: {
    color: "#fff",
    background: "#003057",
    padding: "1.25rem !important",
    textAlign: "center",
    fontSize: "1.10rem",
    border: "none",
  },
};

export const TableRowStyle = (backgroundColor: string): SystemStyleObject => ({
  "& .MuiTableCell-root": {
    paddingRight: "2rem",
    border: "none",
    fontSize: "1.30rem",
    fontWeight: "500 !important",
  },
  "& .MuiTypography-root": {
    fontWeight: "500 !important",
  },
  "& .numberLabel": {
    fontSize: "1.30rem",
  },
  backgroundColor: backgroundColor,
});

export const ColumnFirstStyle = {
  paddingLeft: "1rem",
  width: "9rem",
  paddingRight: "3rem",
};

export const NameCellStyle = (textColor: string): SystemStyleObject => ({
  ...ColumnFirstStyle,
  color: textColor,
});

export const CostStyle = {
  ...ColumnFirstStyle,
  width: "13rem",
};

const ColumnSecondStyle = {
  paddingRight: "1rem",
  paddingLeft: "1rem",
  border: "none",
  textAlign: "right",
};

export const DurationStyle = {
  ...ColumnSecondStyle,
  width: "14rem",
};

export const EmissionStyle = {
  ...ColumnSecondStyle,
  width: "14rem",
};

export const DateFieldStyle = {
  width: "10rem",
  whiteSpace: "nowrap",
  "& .MuiInputBase-input": {
    textAlign: "center",
    fontSize: "1.75rem",
  },
  "& .Mui-disabled": {
    "& .MuiInputBase-input": {
      WebkitTextFillColor: "#fff",
    },
  },
  "& .MuiInputBase-root.Mui-disabled:before": {
    border: "none",
  },
};

import DeleteIcon from "@mui/icons-material/Delete"; // Import the Delete Icon
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { useState } from "react";

import deleteCost from "@/features/cost/api/deleteCost";
import ReusableDialog from "@/shared/components/ReusableDialog";
import { WBSEntryModel } from "@/shared/context/projectWBS/projectWBSModel";
import useProjectWBSContext from "@/shared/context/projectWBS/useProjectWBSContext";

type DeleteCostButtonProps = {
  WBSEntry: WBSEntryModel | null;
  handleRemoveResource: (resourceId: string) => void;
  resourceToDelete: string[];
};

export default function DeleteCostButton({
  WBSEntry,
  handleRemoveResource,
  resourceToDelete,
}: DeleteCostButtonProps) {
  const { refetchProjectWBS } = useProjectWBSContext();
  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };
  const deleteCostAsync = async () => {
    if (WBSEntry) {
      await deleteCost(WBSEntry.id, resourceToDelete[0]);
      handleRemoveResource(resourceToDelete[0]);
      refetchProjectWBS();
    } else {
      throw new Error("WBS Entry is null");
    }
  };

  const handleDelete = () => {
    void deleteCostAsync();
    handleClose();
  };

  return (
    <>
      <MenuItem key="delete" onClick={handleOpen}>
        <DeleteIcon fontSize="small" />
      </MenuItem>

      <ReusableDialog
        isOpen={isModalOpen}
        title="Delete Resource"
        cancelButtonCallback={handleClose}
        confirmButtonCallback={handleDelete}
        confirmButtonText="Delete"
        confirmButtonType="error"
        size="small">
        <Typography variant="h5" sx={{ textAlign: "center" }}>
          Are you sure you want to delete the selected resource?
        </Typography>
      </ReusableDialog>
    </>
  );
}

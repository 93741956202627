import { useMsal } from "@azure/msal-react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Link as RouterLink } from "react-router-dom";

import Logo from "@/shared/assets/logos/eCERTO_flat_white.png";
import { PrimaryButton } from "@/shared/components/buttons";
import { loginRequest } from "@/shared/config/authConfig";

// @important pass to gpt to improve
export default function SignIn() {
  const { instance } = useMsal();
  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <Grid
        item
        xs={false}
        sm={false}
        md={6}
        lg={7}
        sx={{
          display: {
            xs: "none",
            sm: "none",
            md: "flex",
          },
          backgroundRepeat: "no-repeat",
          backgroundColor: "primary.main",
          backgroundSize: "cover",
          backgroundPosition: "center",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <img src={Logo} alt="Logo" width={150} style={{ marginBottom: 10 }} />
        <Typography
          component="h1"
          color="white"
          sx={{
            fontWeight: "light",
            fontSize: {
              // xs: 70,
              // sm: 70,
              md: 80,
              lg: 90,
              xl: 100,
            },
          }}>
          eCERTO
        </Typography>
        <Typography
          component="h2"
          color="white"
          letterSpacing={8}
          sx={{
            fontWeight: "light",
            marginBottom: "10px",
            fontSize: {
              // xs: 10,
              // sm: 10,
              md: 14,
              lg: 17,
              xl: 20,
            },
          }}>
          BEYOND eCOMMERCE
        </Typography>
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>
          <Typography
            component="h1"
            sx={{
              fontWeight: "light",
              fontSize: {
                xs: 70,
                sm: 80,
                md: 85,
                lg: 90,
                xl: 100,
              },
            }}
            color="text.secondary">
            INTEGRATI
          </Typography>
          <Typography
            component="h2"
            color="text.secondary"
            letterSpacing={3}
            sx={{
              mb: 3,
              textAlign: "center",
              fontWeight: {
                xs: "bold",
                sm: "normal",
              },
              fontSize: {
                xs: 10,
                sm: 12,
                md: 13,
                lg: 14,
                xl: 16,
              },
            }}>
            TRANSFORMING YOUR COMMERCIAL DNA
            <span
              style={{
                verticalAlign: "super",
                fontSize: "0.6em",
                marginLeft: "0.1em",
              }}>
              TM
            </span>
          </Typography>

          <PrimaryButton onClick={() => void instance.loginPopup(loginRequest)}>
            Sign In with Microsoft
          </PrimaryButton>
          <Grid container justifyContent="space-around">
            <Grid item>
              <Link component={RouterLink} to="/register" variant="body2">
                Don&rsquo;t have an account? Sign Up
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}

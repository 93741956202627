import LogoutIcon from "@mui/icons-material/Logout";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import SettingsIcon from "@mui/icons-material/Settings";
import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";

import { MenuStyle } from "@/features/navigation/navbar/styles/navbarStyles";
import { RListButton } from "@/shared/components/buttons";
import RMenu from "@/shared/components/RMenu";
import { useTenantManagement } from "@/shared/context/tenantManagementCtx/useManagementTenant";
import TenantManagementView from "@/shared/enums/tenantManagement";
import { useAuth } from "@/shared/utils/auth";

type NavbarUserMenuProps = {
  anchorElUser: HTMLElement | null;
  setAnchorElUser: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
};

export default function NavbarUserMenu({
  anchorElUser,
  setAnchorElUser,
}: NavbarUserMenuProps) {
  const { logOut } = useAuth();
  const { openModalWithContent } = useTenantManagement();

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const logOutHandler = () => {
    void logOut();
  };

  return (
    <Menu
      id="menu-appbar"
      keepMounted
      sx={MenuStyle}
      anchorEl={anchorElUser}
      open={Boolean(anchorElUser)}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      onClose={handleCloseUserMenu}>
      <RMenu>
        <RListButton
          icon={<SettingsIcon />}
          onClick={() => {
            openModalWithContent(TenantManagementView.Profile);
            handleCloseUserMenu();
          }}
          isMenuItem>
          Manage Your Account
        </RListButton>
        <RListButton
          icon={<ManageAccountsIcon />}
          onClick={() => {
            openModalWithContent(TenantManagementView.ManageUsers);
            handleCloseUserMenu();
          }}
          isMenuItem>
          Manage Users
        </RListButton>
        <Divider />
        <RListButton
          icon={<LogoutIcon />}
          onClick={logOutHandler}
          type="error"
          isMenuItem>
          Log out
        </RListButton>
      </RMenu>
    </Menu>
  );
}

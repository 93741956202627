import Box from "@mui/material/Box";
import {
  useMaterialReactTable,
  MaterialReactTable,
  type MRT_Cell,
  MRT_RowSelectionState,
} from "material-react-table";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import editCost from "@/features/cost/api/editCost";
import getCostDataService from "@/features/cost/api/getCostDataService";
import ColumnsCostForProjectBaseline from "@/features/cost/columns/costColumnsForProjectBaseline";
import CostToolbar from "@/features/cost/CostToolbar";
import {
  BaselineOverviewSchema,
  updateBaselineOverviewSchema,
  BaselineOverview,
} from "@/shared/components/baselineOverview";
import useProjectWBSContext from "@/shared/context/projectWBS/useProjectWBSContext";
import ParametricCostTypes from "@/shared/enums/parametricCostTypes";
import { ParameterModels } from "@/shared/types/parameterModels";

import { CostModelForProjectBaseline } from "../types/costModelForProjectBaseline";

import CostActions from "./CostActions";

type CostTableProps = {
  data: CostModelForProjectBaseline[];
  parametricData: ParameterModels[];
};

export default function CostTable({ data, parametricData }: CostTableProps) {
  const [tableData, setTableData] = useState<CostModelForProjectBaseline[]>([]);
  const { outcome: wbsEntryId } = useParams<{
    outcome: string;
  }>();

  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const { getWBSEntryById, isLoading, refetchProjectWBS } = useProjectWBSContext();
  const WBSEntry = getWBSEntryById(wbsEntryId);
  const parametricOutcome = parametricData.find(
    //eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison -- we need to add a type to the parameter to compare enum with enum rather than name with enum. Must be fixed when refactoring
    (item) => item.name === ParametricCostTypes.Outcome
  )!;
  const OverviewItems = updateBaselineOverviewSchema(
    BaselineOverviewSchema,
    WBSEntry,
    parametricOutcome
  );
  const baselineOverviewTitle = WBSEntry
    ? `Level ${WBSEntry.level} — ${WBSEntry.name}`
    : "Error: Title was unable to load";

  useEffect(() => {
    setTableData(data);
  }, [data]);
  const handleRemoveResource = (resourceId: string) => {
    setTableData((prevTableData) =>
      prevTableData.filter((resource) => resource.id !== resourceId)
    );
  };
  // Gets all resources from cost table and update the state
  const addResource = useCallback(async () => {
    if (WBSEntry) {
      // Fetch the updated resources costs from API
      const updatedResourcesCosts = await getCostDataService(
        WBSEntry.projectId,
        WBSEntry.id
      );
      setTableData(updatedResourcesCosts);
    }
  }, [WBSEntry, setTableData]);

  const handleAddResource = () => {
    void addResource();
  };

  const handleSaveCell = async (
    cell: MRT_Cell<CostModelForProjectBaseline>,
    value: number
  ) => {
    const resourceToUpdate = cell.row.original;

    // Create the request body for the PUT API call
    const body = {
      resourceId: resourceToUpdate.id,
      deliverableId: resourceToUpdate.requiredDeliverableId as string,
      quantity: value,
      distribution: resourceToUpdate.distribution,
      upperBound: value,
      lowerBound: value,
      allocatedQuantity: resourceToUpdate.allocatedQuantity as number,
      selectedBaseline: resourceToUpdate.selectedBaseline as number,
      resetAllocations: false,
    };

    try {
      // Make the PUT API call to save the changes
      await editCost(body);

      if (WBSEntry) {
        // Fetch the updated resources costs from API
        const updatedResourcesCosts = await getCostDataService(
          WBSEntry.projectId,
          WBSEntry.id
        );

        // Find the updated resource in the fetched data
        const updatedResource = updatedResourcesCosts.find(
          (resource) => resource.id === resourceToUpdate.id
        );

        if (updatedResource) {
          setTableData((prevTableData) =>
            // Replace the old resource with the updated resource in the table data
            prevTableData.map((item) =>
              item.id === updatedResource.id ? updatedResource : item
            )
          );
        }
      }
      refetchProjectWBS();
    } catch (error) {
      throw new Error("An unexpected error occurred");
    }
  };

  const columns = ColumnsCostForProjectBaseline(OverviewItems, handleSaveCell);

  const table = useMaterialReactTable({
    autoResetPageIndex: false,
    columns,
    data: tableData,
    editDisplayMode: "cell",
    enableBottomToolbar: true,
    enableColumnActions: true,
    enableColumnFilters: true,
    enableColumnFilterModes: true,
    enableColumnPinning: true,
    enableColumnResizing: true,
    enableFullScreenToggle: false,
    enableGlobalFilterModes: true,
    enablePagination: true,
    enableRowActions: true,
    enableSorting: true,
    enableStickyHeader: true,
    enableTopToolbar: true,
    getRowId: (originalRow) => originalRow.id,
    initialState: {
      columnPinning: {
        left: ["category"],
        right: ["mrt-row-actions"],
      },
      columnVisibility: {
        distribution: false,
        lowerBound: false,
        upperBound: false,
      },
      density: "compact",
    },
    layoutMode: "grid",
    muiBottomToolbarProps: { sx: () => ({ background: "#fff" }) },
    muiTableFooterCellProps: { sx: () => ({ background: "#fff" }) },
    renderTopToolbarCustomActions: () => [
      <CostToolbar
        key={wbsEntryId ?? "1"}
        wbsEntryId={wbsEntryId ?? ""}
        handleAddResource={handleAddResource}
      />,
    ],
    // Header styles
    muiTableHeadCellProps: {
      sx: () => ({
        background: "var(--primary-color)",
        color: "#fff",
        fontSize: "12px",
      }),
    },
    muiTableProps: {
      sx: {
        tableLayout: "fixed",
        overflowX: "hidden",
      },
    },
    muiTableBodyCellProps: {
      align: "center",
      sx: () => ({ background: "#fff" }),
    },
    muiTableContainerProps: {
      sx: {
        "&::-webkit-scrollbar": {
          backgroundColor: "#f5f5f5",
          borderRadius: "10px",
          height: "12px !important",
          width: "8px !important",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#ccc",
          borderRadius: "10px",
          "&:active": {
            backgroundColor: "#808080",
          },
          "&:hover": {
            backgroundColor: "#b3b3b3",
          },
        },
        maxHeight: 700,
        overflow: "auto",
        overflowY: "auto",
      },
    },

    muiTopToolbarProps: { sx: () => ({ background: "#fff" }) },
    onRowSelectionChange: setRowSelection,
    paginateExpandedRows: false,
    positionToolbarAlertBanner: "none",
    renderRowActions: ({ row }) => (
      <CostActions
        WBSEntry={WBSEntry}
        handleRemoveResource={handleRemoveResource}
        resourceToDelete={row.original.id}
      />
    ),

    state: {
      rowSelection,
    },
  });

  return (
    <>
      <Box sx={{ paddingBottom: "24px" }}>
        <BaselineOverview
          OverviewItems={OverviewItems}
          isLoading={isLoading}
          title={baselineOverviewTitle}
        />
      </Box>
      <MaterialReactTable table={table} />
    </>
  );
}

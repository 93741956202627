import { Theme } from "@mui/material/styles";

export const AppBarStyle = (theme: Theme) => ({
  zIndex: theme.zIndex.drawer + 1,
});

export const ContainerNavbarTitleStyle = {
  flexGrow: 1,
  display: "flex",
  alignItems: "center",
};

export const ImageNavbarTitleStyle = {
  width: "2.75rem",
};

export const TextNavbarTitleStyle = {
  marginLeft: "1rem",
  letterSpacing: "0.15rem",
  fontWeight: "400",
  color: "inherit",
  textDecoration: "none",
};

export const IconButtonStyle = {
  padding: "0rem",
  marginLeft: "1rem",
};

export const AvatarStyle = {
  backgroundColor: "var(--ecerto-breakdown-brown)",
};

export const MenuStyle = {
  marginTop: "3.5rem",
};

export const NavbarAuthButtonStyle = {
  my: "0.5rem",
  border: "0.15rem solid white",
  color: "white",
  marginLeft: "0.5rem",
};

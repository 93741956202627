import { AxiosResponse, AxiosError } from "axios";

import { RISK_API_ENDPOINTS } from "@/features/risk/api/endpoints";
import getProjectBaseRisks from "@/features/risk/api/riskBaselineService";
import newRiskModelToLegacyModel from "@/features/risk/helpers/formatLegacyRiskModels";
import { PostRiskLegacyBaseModel } from "@/features/risk/types/riskLegacyModel";
import { RiskBaseModel } from "@/features/risk/types/riskModels";
import apiRequests from "@/shared/config/axiosConfig";

type UpdateRiskResponseData = {
  deliverableId: string;
};

/**
 * Retrieves a risk by its ID.
 * @param id The ID of the risk to retrieve.
 * @returns A Promise that resolves to a RiskModel object, or null if an error occurs..
 * @throws An error if the request fails or returns an error status code.
 */
export default async function updateRisk(risk: RiskBaseModel): Promise<RiskBaseModel[]> {
  // might need to pass it to legacyModel
  const legacyRiskModel: PostRiskLegacyBaseModel = newRiskModelToLegacyModel(risk);
  try {
    if (!legacyRiskModel.id) {
      throw new Error("Risk ID is undefined");
    }
    const url = RISK_API_ENDPOINTS.UPDATE_RISKS.replace("{riskId}", legacyRiskModel.id);
    const response: AxiosResponse<UpdateRiskResponseData> = await apiRequests.put(
      url,
      legacyRiskModel
    );

    if (response.status >= 200 && response.status < 300) {
      // @todo:  instead of having to call getProjectBaselineRisks we should get that data from the response of the api call
      const risks: RiskBaseModel[] = await getProjectBaseRisks(
        response.data.deliverableId
      );
      return risks;
    }

    throw new Error(`Unknown error occurred with status code ${response.status}`);
  } catch (e) {
    // @todo Handle Error Below
    const error = e as AxiosError;
    switch (error.response?.status) {
      case 400:
        throw new Error("400: Bad Request");
      case 401:
        throw new Error("401: Unauthorized");
      case 404:
        throw new Error("404: Not Found");
      default:
        throw new Error(
          `Unknown error occurred with status code ${error.response?.status}`
        );
    }
  }
}

import { Box, Typography } from "@mui/material";
import { MRT_Row, type MRT_ColumnDef } from "material-react-table";
import { useMemo } from "react";

import { RiskBaseModel } from "@/features/risk/types/riskModels";
import { OverviewItemsModel } from "@/shared/components/baselineOverview";
import Footer from "@/shared/components/FooterForColumns";
import { RNumberInputLabel, RNumberLabel } from "@/shared/components/inputs";
import { SMALL_AMOUNT_RANGE } from "@/shared/constants";

type HandleSaveCellType = (
  riskToUpdate: RiskBaseModel,
  name: string,
  value: number
) => void;

const ColumnsForRiskTable = ({
  overviewItems,
  handleSaveCell,
}: {
  overviewItems: OverviewItemsModel[];
  handleSaveCell: HandleSaveCellType;
}) =>
  useMemo<MRT_ColumnDef<RiskBaseModel>[]>(
    () => [
      {
        accessorKey: "name",
        header: "Name",
        minSize: 90,
        size: 150,
        maxSize: 250,
        muiTableBodyCellProps: {
          align: "left",
        },
        Cell: ({ cell }) => (
          <Typography
            style={{
              fontWeight: "bold",
            }}>
            {`${cell.getValue<string>()}`}
          </Typography>
        ),
      },
      {
        accessorKey: "probabOfOccurrence",
        header: "Probability",
        size: 55,
        enableClickToCopy: false,
        muiTableBodyCellProps: {
          align: "right",
        },
        Cell: ({ row }: { row: MRT_Row<RiskBaseModel> }) => {
          return (
            <RNumberInputLabel
              value={row.original.probabOfOccurrence ?? 0}
              category={"percentage"}
              onInputChange={(newValue) => {
                void handleSaveCell(row.original, "probabOfOccurrence", newValue);
              }}
              allowNegative
              decimalScaleLabel={0}
            />
          );
        },
      },
      {
        accessorKey: "modeDuration",
        header: "Duration (days)",
        size: 85,
        muiTableBodyCellProps: {
          align: "right",
        },
        Cell: ({ row }: { row: MRT_Row<RiskBaseModel> }) => {
          return (
            <RNumberInputLabel
              value={row.original.modeDuration ?? 0}
              category={"duration"}
              onInputChange={(newValue) => {
                void handleSaveCell(row.original, "modeDuration", newValue);
              }}
              decimalScaleLabel={0}
            />
          );
        },
      },
      {
        accessorKey: "modeRemedialCost",
        header: "Remedial Cost",
        minSize: 75,
        size: 90,
        maxSize: 150,
        muiTableBodyCellProps: {
          align: "right",
        },
        Cell: ({ row }: { row: MRT_Row<RiskBaseModel> }) => {
          return (
            <RNumberInputLabel
              value={row.original.modeRemedialCost ?? 0}
              category={"currency"}
              onInputChange={(newValue) => {
                void handleSaveCell(row.original, "modeRemedialCost", newValue);
              }}
              decimalScaleLabel={0}
              range={SMALL_AMOUNT_RANGE}
              allowNegative
            />
          );
        },
      },
      {
        accessorKey: "undefined",
        header: "Emissions",
        size: 60,
        muiTableBodyCellProps: {
          align: "right",
        },
        Cell: () => {
          return (
            <RNumberInputLabel
              value={0}
              category={"emissions"}
              // eslint-disable-next-line @typescript-eslint/no-empty-function -- waiting for implementation
              onInputChange={() => {}}
              decimalScaleLabel={0}
            />
          );
        },
      },
      {
        accessorKey: "impactCost",
        header: "Impact: Cost",
        size: 100,
        enableEditing: false,
        muiTableBodyCellProps: {
          align: "right",
        },
        muiTableHeadCellProps: () => ({
          style: { backgroundColor: "var(--ecerto-red)" },
          sx: {
            "& .MuiDivider-root": {
              border: "2px solid var(--ecerto-red-dark) !important",
            },
          },
        }),

        Cell: ({ row }: { row: MRT_Row<RiskBaseModel> }) => {
          return (
            <Box
              sx={{
                marginRight: "0.5rem",
              }}>
              <RNumberLabel
                value={row.original.modeRemedialCost ?? 0}
                category={"currency"}
              />
            </Box>
          );
        },
        Footer: () => (
          <Footer id="impactCost" items={overviewItems} category={"currency"} />
        ),
      },
      {
        accessorKey: "impactDuration",
        header: "Impact: Duration (days)",
        size: 110,
        enableEditing: false,
        muiTableBodyCellProps: {
          align: "right",
        },
        muiTableHeadCellProps: () => ({
          style: { backgroundColor: "var(--ecerto-red)" },
          sx: {
            "& .MuiDivider-root": {
              border: "2px solid var(--ecerto-red-dark) !important",
            },
          },
        }),
        Cell: ({ row }: { row: MRT_Row<RiskBaseModel> }) => {
          return (
            <Box
              sx={{
                marginRight: "0.5rem",
              }}>
              <RNumberLabel
                value={row.original.impactDuration ?? 0}
                category={"duration"}
              />
            </Box>
          );
        },
        Footer: () => (
          <Footer id="impactDuration" items={overviewItems} category={"duration"} />
        ),
      },
      {
        accessorKey: "none",
        header: "Impact: Emissions",
        size: 100,
        enableEditing: false,
        muiTableBodyCellProps: {
          align: "right",
        },
        muiTableHeadCellProps: () => ({
          style: { backgroundColor: "var(--ecerto-red)" },
          sx: {
            "& .MuiDivider-root": {
              border: "2px solid var(--ecerto-red-dark) !important",
            },
          },
        }),
        Cell: () => {
          return (
            <Box
              sx={{
                marginRight: "0.5rem",
              }}>
              <RNumberLabel value={0} category={"emissions"} />
            </Box>
          );
        },
        Footer: () => (
          <Footer id="impactEmissions" items={overviewItems} category={"emissions"} />
        ),
      },
    ],
    [overviewItems, handleSaveCell]
  );

export default ColumnsForRiskTable;

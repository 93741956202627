import { Box, Tooltip, Typography } from "@mui/material";
import { MRT_ColumnDef } from "material-react-table";
import { useMemo } from "react";
import ReactMarkdown from "react-markdown";

import { WBSEntryViewModel } from "@/shared/context/projectWBS/projectWBSModel";

const ScopeStatementCellStyle = () => ({
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

const ProjectScopeTableColumns = () =>
  useMemo<MRT_ColumnDef<WBSEntryViewModel>[]>(
    () => [
      {
        accessorKey: "wbsCode",
        header: "Code",
        // size: 100,
        maxSize: 50,
        enableResizing: false,
        enableSorting: true,
        enableColumnActions: false,
        muiTableHeadCellProps: {
          align: "left",
        },
        Cell: ({ row }) => <Typography>{row.original.wbsCode}</Typography>,
      },
      {
        accessorKey: "name",
        header: "Name",
        enableSorting: false,
        size: 300,
        muiTableBodyCellProps: {
          align: "left",
        },
        accessorFn: (row: WBSEntryViewModel) => `${row.name} ${row.level}`,
        Cell: ({ row }) => (
          <span>
            <Typography
              style={{
                fontWeight: "bold",
              }}>
              {row.original.name}
            </Typography>
            <Typography>{`Level ${row.original.level}`}</Typography>
          </span>
        ),
      },
      {
        accessorKey: "description",
        header: "Scope Statement",
        size: 300,
        enableSorting: false,
        muiTableBodyCellProps: {
          align: "left",
        },
        Cell: ({ row }) => {
          const allowedElements = ["h1", "li", "ol", "ul", "hr", "p", "span", "div"];
          const components = {
            h1: "h2" as keyof JSX.IntrinsicElements,
          };
          return (
            <Tooltip
              title={
                <ReactMarkdown allowedElements={allowedElements} components={components}>
                  {row.original.description}
                </ReactMarkdown>
              }>
              <Box sx={ScopeStatementCellStyle}>{row.original.description}</Box>
            </Tooltip>
          );
        },
      },
      // {
      //   accessorKey: "include",
      //   header: "Include",
      //   enableSorting: false,
      //   size: 100,
      //   muiTableBodyCellProps: {
      //     align: "left",
      //   },
      //   Cell: () => <Checkbox disabled checked aria-label="include" />,
      // },
    ],
    []
  );

export default ProjectScopeTableColumns;

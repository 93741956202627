import { AxiosError, AxiosResponse } from "axios";

import { PROJECTS_API_ENDPOINTS } from "@/features/portfolio/api/endpoints";
import { PortfolioRowModel, Project } from "@/features/portfolio/models/portfolioModel";
import apiRequests from "@/shared/config/axiosConfig";

import mapProjectsToPortfolioRowModels, {
  sortPortfolioData,
} from "../utils/portfolioHelpers";

export default async function getPortfolio(): Promise<PortfolioRowModel[]> {
  try {
    const url = PROJECTS_API_ENDPOINTS.GET_PORTFOLIO;
    const response: AxiosResponse<Project[]> = await apiRequests.get(url);
    const { data, status } = response;

    if (status === 200) {
      const portfolio: Project[] = data;

      const mappedPortfolioData: PortfolioRowModel[] =
        mapProjectsToPortfolioRowModels(portfolio);

      // Sort parent items based on 'order' property
      const sortedPortfolioData = sortPortfolioData(mappedPortfolioData);

      return sortedPortfolioData;
    }

    throw new Error(`Unknown error occurred with status code ${status}`);
  } catch (e) {
    // @todo Handle Error Below
    const error = e as AxiosError;
    switch (error.response?.status) {
      case 400:
        throw new Error("400: Bad Request");
      case 401:
        throw new Error("401: Unauthorized");
      case 404:
        throw new Error("404: Not Found");
      default:
        throw new Error(
          `Unknown error occurred with status code ${error.response?.status}`
        );
    }
  }
}

import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { Formik, Form, FormikProps } from "formik";
import { RefObject } from "react";
import * as yup from "yup";

import { AddConceptModel } from "@/features/portfolio/models/addConceptModel";
import RChip from "@/shared/components/RChip";
import LifeCycleStage from "@/shared/enums/lifeCycleStage";
import Risk, { getRiskColorFromIndex } from "@/shared/enums/risk";
import ProjectScenarios from "@/shared/enums/scenarios";
import { getTextColorTemp } from "@/shared/utils";

const validationSchema = yup.object({
  name: yup
    .string()
    .max(100, "Name should not be longer than 75 characters")
    .required("Name is required"),
  programmeName: yup
    .string()
    .max(100, "Programme name should not be longer than 75 characters")
    .required("Programme name is required"),
  riskLevel: yup.string().required("Risk level is required"),
  lifeCycleStage: yup.string().required("Life cycle stage is required"),
  selectedScenario: yup.string().required("Commercial Scenario is required"),
  projectCode: yup.number().required("Project Code is required"),
});

type AddConceptFormProps = {
  projectId?: string;
  onConfirm: (newProject: AddConceptModel) => void;
  innerRef: RefObject<FormikProps<AddConceptModel>>;
};

export default function AddConceptForm({
  projectId,
  onConfirm,
  innerRef,
}: AddConceptFormProps) {
  const initialValues: AddConceptModel = {
    name: "",
    programmeName: "N/A",
    riskLevel: Risk["Very High"],
    lifeCycleStage: LifeCycleStage.Visualise,
    selectedScenario: ProjectScenarios[0] as unknown as ProjectScenarios,
    parentId: projectId ?? "",
    projectCode: 1,
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      innerRef={innerRef}
      onSubmit={(values) => {
        try {
          onConfirm(values);
        } catch (err) {
          if (err instanceof Error) {
            throw new Error(`Failed to confirm concept: ${err.message}`);
          } else {
            throw new Error("Failed to confirm concept: An unknown error occurred");
          }
        }
      }}>
      {({ values, errors, touched, handleChange, handleBlur }) => (
        <Box sx={{ "& .MuiTextField-root": { mb: 2 }, paddingTop: "1rem" }}>
          <Form>
            <TextField
              required
              label="Concept Name"
              name="name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name && errors.name}
              fullWidth
            />
            <TextField
              required
              label="Commercial Scenario"
              select
              name="selectedScenario"
              value={values.selectedScenario}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.selectedScenario && Boolean(errors.selectedScenario)}
              SelectProps={{
                // @check add check for license type from backend (in user info right now)
                renderValue: (selected) => {
                  const selectedValue = selected as string;

                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}>
                      {selectedValue}
                      {selectedValue !== ProjectScenarios[0] && <RChip label="PRO" />}
                    </div>
                  );
                },
              }}
              helperText={touched.selectedScenario && errors.selectedScenario}
              fullWidth>
              {Object.values(ProjectScenarios)
                .filter((value) => typeof value === "string")
                .map((value) => (
                  <MenuItem
                    key={value}
                    value={value}
                    disabled={value !== ProjectScenarios[0]}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}>
                    {value}
                    {value !== ProjectScenarios[0] && <RChip label="PRO" />}
                  </MenuItem>
                ))}
            </TextField>
            <TextField
              required
              label="Life Cycle Stage"
              select
              name="lifeCycleStage"
              value={values.lifeCycleStage}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.lifeCycleStage && Boolean(errors.lifeCycleStage)}
              helperText={touched.lifeCycleStage && errors.lifeCycleStage}
              fullWidth>
              {Object.values(LifeCycleStage)
                .filter((value) => typeof value === "string")
                .map((value, index) => (
                  // @check change value={key} to value={displayName} if we want to send the name in the future
                  <MenuItem key={value} value={index}>
                    {value}
                  </MenuItem>
                ))}
            </TextField>
            <TextField
              required
              label="Risk Level"
              select
              SelectProps={{
                renderValue: (selected) => {
                  return Risk[selected as keyof typeof Risk];
                },
              }}
              name="riskLevel"
              value={values.riskLevel}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.riskLevel && Boolean(errors.riskLevel)}
              helperText={touched.riskLevel && errors.riskLevel}
              fullWidth>
              {Object.values(Risk)
                .filter((value) => typeof value === "string")
                .map((value, index) => (
                  <MenuItem
                    key={value}
                    value={index}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}>
                    {value}
                    <RChip
                      backgroundColor={getRiskColorFromIndex(index)}
                      color={getTextColorTemp(getRiskColorFromIndex(index))}
                      style={{
                        borderRadius: "0.5rem",
                        height: "0.75rem",
                        width: "2rem",
                      }}
                    />
                  </MenuItem>
                ))}
            </TextField>
            <TextField
              required
              type="number"
              label="Project Code"
              name="projectCode"
              value={values.projectCode}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.name && Boolean(errors.projectCode)}
              helperText={touched.name && errors.projectCode}
              fullWidth
            />
          </Form>
        </Box>
      )}
    </Formik>
  );
}

import { Box } from "@mui/material";

import {
  formatDateToAvoidTimezoneIssues,
  updateWBSEntry,
} from "@/features/projectSchedule/helpers/helper";
import { DurationCellTypes } from "@/features/projectSchedule/types/types";
import { RNumberInputLabel, RNumberLabel } from "@/shared/components/inputs";

const BoxStyle = () => ({
  marginRight: "20px",
  width: "6rem",
  color: "rgba(0, 48, 87, 0.8)",
});
export default function DurationCell({
  scheduleEntry,
  onDurationChange,
}: DurationCellTypes) {
  const isEditable = !scheduleEntry.isDisabled;
  const duration = scheduleEntry.duration ?? 0;

  const handleDurationChange = (newDuration: number) => {
    // Must transform start date to a string to avoid timezone issues e.g.(start date goes back 1 day when duration changes)
    const startDate = formatDateToAvoidTimezoneIssues(scheduleEntry.start);

    const newWBSEntry = updateWBSEntry(scheduleEntry, startDate, newDuration);

    void onDurationChange(newWBSEntry, "FromTable");
  };
  return (
    <Box sx={BoxStyle}>
      {isEditable ? (
        <RNumberInputLabel
          value={duration}
          category="duration"
          decimalScaleInput={2}
          decimalScaleLabel={0}
          onInputChange={handleDurationChange}
        />
      ) : (
        <RNumberLabel value={duration} category="duration" decimalScale={0} />
      )}
    </Box>
  );
}

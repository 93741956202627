import { Box, CircularProgress } from "@mui/material";
import {
  MaterialReactTable,
  MRT_RowSelectionState,
  useMaterialReactTable,
} from "material-react-table";
import { useCallback, useEffect, useState } from "react";

import getResourceForCatalogue from "@/features/cost/resourceCatalogue/api/getResourceCatalogueDataService";
import EditResourceCatalogueButton from "@/features/cost/resourceCatalogue/components/EditResourceCatalogueButton";
import ResourceCatalogueRowActions from "@/features/cost/resourceCatalogue/components/ResourceCatalogueRowActions";

import ResourceCatalogueTableColumns from "../columns/ResourceCatalogueTableColumns";
import { ResourcesModel } from "../types/resourcesModel";
// import ResourceUploader from "@/features/cost/resourceCatalogue/components/BatchUpload";

type ResourceCatalogueProps = {
  outcome: string;
  handleAddResource: () => void;
};

export default function ResourceCatalogueTable({
  outcome,
  handleAddResource,
}: ResourceCatalogueProps) {
  const [data, setData] = useState<ResourcesModel[] | undefined>(undefined);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  const columns = ResourceCatalogueTableColumns();

  // Gets all resources from resources table and update the state
  const handleRefetchResource = useCallback(async () => {
    if (!outcome) return;

    // Only reset rowSelection if outcome has changed
    const res = await getResourceForCatalogue(outcome);
    setData(res);
    setRowSelection({});
  }, [outcome]);

  const catalogueAndCostRefetch = useCallback(async () => {
    try {
      await handleRefetchResource();
      handleAddResource();
    } catch (error: unknown) {
      if (error instanceof Error) {
        throw new Error(`Error refetching resource: ${error.message}`);
      } else {
        throw new Error("An unknown error occurred while refetching resource.");
      }
    }
  }, [handleRefetchResource, handleAddResource]);
  const handleCatalogueAndCostRefetch = () => {
    void catalogueAndCostRefetch();
  };
  useEffect(() => {
    if (data === undefined) {
      void handleRefetchResource(); // Call the memoized data fetching function
    }
  }, [data, handleRefetchResource]); // Depend on the memoized function and data

  const table = useMaterialReactTable({
    columns,
    data: data ?? [], // Provide an empty array if data is undefined
    initialState: {
      density: "compact",
      pagination: { pageSize: 15, pageIndex: 1 },
      columnPinning: {
        // pin region, checkbox and expand columns to the left
        left: ["mrt-row-select", "mrt-row-expand", "mrt-row-actions"],
      },
    },
    onRowSelectionChange: setRowSelection,
    state: {
      rowSelection,
    },
    layoutMode: "grid",
    positionToolbarAlertBanner: "none",
    // Header styles
    muiTableHeadCellProps: {
      sx: () => ({
        background: "var(--primary-color)",
        color: "#fff",
        fontSize: "12px",
        "&.MuiTableCell-root.MuiTableCell-head": { paddingLeft: "16px" },
      }),
    },
    muiTableBodyCellProps: {
      align: "center",
    },
    muiTableProps: {
      sx: {
        tableLayout: "fixed",
      },
    },
    muiTableContainerProps: {
      sx: {
        // height: "900px",
        height: "75vh",
        // maxWidth: 1500,
        overflow: "auto", // Enable scrolling
        // make only horizontal scroll bar visible
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          width: "8px !important",
          backgroundColor: "#f5f5f5",
          height: "12px !important",
          borderRadius: "10px",
        },
        "&::-webkit-scrollbar-thumb": {
          borderRadius: "10px",
          backgroundColor: "#ccc",
          "&:hover": {
            backgroundColor: "#b3b3b3",
          },
          "&:active": {
            backgroundColor: "#808080",
          },
        },
      },
    },
    muiTopToolbarProps: { sx: () => ({ background: "#fff" }) },
    muiSelectCheckboxProps: {
      color: "primary",
    },
    // renderTopToolbarCustomActions: () => [
    //   ResourceCatalogueRowActions({   refetchProjects={fetchResourceCatalogueDeliverables}
    //     tableInstanceRef={table}
    //     rowSelection={rowSelection}}),

    // ],
    enableFullScreenToggle: false,
    enableEditing: true,
    enableRowSelection: true,
    enableColumnResizing: true,
    paginateExpandedRows: true,
    enableGlobalFilterModes: true,
    autoResetPageIndex: false,
    enableStickyHeader: true,
    enableColumnFilterModes: true,
    enableColumnPinning: true,
    enableColumnActions: true,
    enableColumnFilters: true,
    enablePagination: true,
    enableSorting: true,
    enableBottomToolbar: true,
    enableTopToolbar: true,
    enableRowActions: true,
    getRowId: (originalRow) => originalRow.id,
    renderRowActions: ({ row }) => [
      EditResourceCatalogueButton({
        row,
        onCatalogueAndCostRefetch: handleCatalogueAndCostRefetch,
      }),
    ],
  });

  if (data === undefined) {
    // In your component
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexGrow={1}
        height="100%"
        width="100%">
        <CircularProgress color="primary" size={50} />
      </Box>
    );
  }
  return (
    <>
      <ResourceCatalogueRowActions
        onCatalogueAndCostRefetch={handleCatalogueAndCostRefetch}
        rowSelection={rowSelection}
        outcome={outcome}
      />
      {/* <ResourceUploader /> */}
      <MaterialReactTable table={table} />
    </>
  );
}

import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import { Button, Drawer } from "@mui/material";
import Box from "@mui/material/Box";
import { useState } from "react";
import { Outlet } from "react-router-dom";

import { Breadcrumb, Sidebar, StrategyDefinitionStepper } from "@/features/navigation";
import RLoading from "@/shared/components/RLoading";
import useProjectWBSContext from "@/shared/context/projectWBS/useProjectWBSContext";

export default function StrategyDefinitionLayout() {
  const initialDrawerWidth = 30;
  const [drawerWidth, setDrawerWidth] = useState(initialDrawerWidth);
  const { isLoading } = useProjectWBSContext();
  const [open, setOpen] = useState(true);
  const [isHovering, setIsHovering] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const [isBreakdownStructuresButtonOpen, setIsBreakdownStructuresButtonOpen] =
    useState(false);
  // needs to be refactored
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
    if (newOpen) {
      setDrawerWidth(initialDrawerWidth);
      setIsButtonVisible(false);
    } else {
      setDrawerWidth(0);
      setIsButtonVisible(true);
    }
  };

  return (
    <Box component="main" sx={{ display: "flex" }}>
      <Drawer
        variant="persistent"
        open={open}
        BackdropProps={{ invisible: true }}
        sx={{
          width: `${drawerWidth}rem`,
        }}>
        <Sidebar
          drawerWidth={drawerWidth}
          setDrawerWidth={setDrawerWidth}
          isLoading={isLoading}
          setIsHovering={setIsHovering}
          isBreakdownStructuresButtonOpen={isBreakdownStructuresButtonOpen}
          setIsBreakdownStructuresButtonOpen={setIsBreakdownStructuresButtonOpen}
        />
      </Drawer>
      {!open && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            borderLeft: "1rem solid var(--primary-color)",
          }}
        />
      )}
      <Box
        mt={3}
        sx={{
          width: `calc(100% - ${drawerWidth}rem)`,
          marginLeft: `${drawerWidth === 0 ? drawerWidth : 0.5}rem`,
          transition: "margin-left 0.75s, width 0.75s",
        }}>
        <Breadcrumb isLoading={isLoading} />
        {!isLoading ? (
          <>
            {(isHovering || isButtonVisible) && !isBreakdownStructuresButtonOpen && (
              <Button
                onClick={toggleDrawer(!open)}
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
                variant="text"
                size="small"
                sx={{
                  cursor: "pointer",
                  backgroundColor: "#fff",
                  margin: "0",
                  minWidth: "0",
                  padding: "0",
                  color: "#8e8f90",
                  borderRadius: "50%",
                  border: `solid 0.2rem  #003057`,
                  width: "2rem",
                  height: "2rem",
                  webkitBoxShadow:
                    "0.4375rem 0.4375rem 1.5625rem -1.25rem rgba(0, 0, 0, 1)",
                  mozBoxShadow: "0.4375rem 0.4375rem 1.5625rem -1.25rem rgba(0, 0, 0, 1)",
                  boxShadow: "0.4375rem 0.4375rem 1.5625rem -1.25rem rgba(0, 0, 0, 1)",
                  zIndex: 2000,
                  position: "fixed",
                  left: open ? `${drawerWidth - 1}rem` : ".1rem",
                  top: "9rem",
                  "&:active": {
                    backgroundColor: "#fff",
                  },
                  "&:hover": {
                    backgroundColor: "#fff",
                  },
                  "&:focus": {
                    backgroundColor: "#fff",
                  },
                }}>
                {open ? (
                  <KeyboardArrowLeftRoundedIcon sx={{ color: "var(--primary-color)" }} />
                ) : (
                  <KeyboardArrowRightRoundedIcon />
                )}
              </Button>
            )}
            <StrategyDefinitionStepper />
            <Outlet />
          </>
        ) : (
          <RLoading />
        )}
      </Box>
    </Box>
  );
}

import { TableCell, TableRow, TypographyProps } from "@mui/material";
import { useCallback } from "react";

import editOverviewTableService from "@/features/outcomeOverview/api/editOverviewTableService";
import {
  EmissionStyle,
  DurationStyle,
  CostStyle,
  NameCellStyle,
  TableRowStyle,
} from "@/features/outcomeOverview/styles/styles";
import { OverviewTableModel } from "@/features/outcomeOverview/types/types";
import { RNumberInputLabel, RNumberLabel } from "@/shared/components/inputs";
import {
  WBSEntryModel,
  WBSEntryViewModel,
} from "@/shared/context/projectWBS/projectWBSModel";
import useProjectWBSContext from "@/shared/context/projectWBS/useProjectWBSContext";
import { getTextColor } from "@/shared/utils";

type TableRowProps = {
  row: OverviewTableModel;
  wbsEntry: WBSEntryModel | WBSEntryViewModel;
};

export default function OverviewTableRow({ row, wbsEntry }: TableRowProps) {
  const { refetchProjectWBS } = useProjectWBSContext();
  const { id, backgroundColor, name, cost, isEditable, duration, emissions } = row;
  const textColor = getTextColor(backgroundColor);

  const handleEditTable = useCallback(
    async (column: "cost" | "duration" | "emissions", value?: number): Promise<void> => {
      const updatedRow = {
        ...row,
        [column]: value,
      };
      if (wbsEntry) {
        await editOverviewTableService(updatedRow, wbsEntry as WBSEntryModel);
        refetchProjectWBS();
      } else {
        throw new Error("WBS Entry is null");
      }
    },
    [wbsEntry, refetchProjectWBS, row]
  );
  const typographyProps: TypographyProps = {
    variant: "h5",
    fontWeight: 500,
    color: textColor,
  };
  return (
    <TableRow key={id} sx={TableRowStyle(backgroundColor)}>
      <TableCell sx={NameCellStyle(textColor)}>{name}</TableCell>
      <TableCell sx={CostStyle}>
        {isEditable && name !== "Contingency" ? (
          <RNumberInputLabel
            value={cost}
            category="currency"
            decimalScaleInput={2}
            decimalScaleLabel={0}
            onInputChange={(value) => {
              void handleEditTable("cost", Number(value));
            }}
            typographyProps={typographyProps}
          />
        ) : (
          <RNumberLabel
            value={cost}
            category="currency"
            decimalScale={0}
            typographyProps={typographyProps}
          />
        )}
      </TableCell>
      <TableCell sx={DurationStyle}>
        {isEditable && name !== "Contingency" ? (
          <RNumberInputLabel
            value={duration}
            category="duration"
            decimalScaleInput={2}
            decimalScaleLabel={0}
            onInputChange={(value) => {
              void handleEditTable("duration", Number(value));
            }}
            typographyProps={typographyProps}
          />
        ) : (
          <RNumberLabel
            value={duration}
            category="duration"
            decimalScale={0}
            typographyProps={typographyProps}
          />
        )}
      </TableCell>
      <TableCell sx={EmissionStyle}>
        {isEditable ? (
          <RNumberInputLabel
            value={emissions}
            category="emissions"
            decimalScaleInput={2}
            decimalScaleLabel={0}
            onInputChange={(value) => {
              void handleEditTable("emissions", Number(value));
            }}
            typographyProps={typographyProps}
          />
        ) : (
          <RNumberLabel
            value={emissions}
            category="emissions"
            decimalScale={0}
            typographyProps={typographyProps}
          />
        )}
      </TableCell>
    </TableRow>
  );
}

import DeleteIcon from "@mui/icons-material/Delete";
import KeyIcon from "@mui/icons-material/Key";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import { useState } from "react";

import ChangeRoleButton from "@/features/tenantManagement/components/userManagement/ChangeRoleButton";
import RemoveUsersButton from "@/features/tenantManagement/components/userManagement/RemoveUsersButton";
import RListButton from "@/shared/components/buttons/RListButton";
import RMenu from "@/shared/components/RMenu";

export default function UserManagementActions() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpenUMS = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseUMS = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton onClick={handleOpenUMS}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseUMS}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}>
        <RMenu>
          <RListButton icon={<KeyIcon />} isMenuItem>
            <ChangeRoleButton />
          </RListButton>
          <Divider />
          <RListButton icon={<DeleteIcon />} type="error" isMenuItem>
            <RemoveUsersButton />
          </RListButton>
        </RMenu>
      </Menu>
    </Box>
  );
}

export const API_BASE_URL = import.meta.env.VITE_API_BASE_URL as string;
type SharedApiEndpoints = {
  ADD_PROJECT_DELIVERABLES: string;
  GET_CONCEPT_WBS: string;
};

export const SHARED_API_ENDPOINTS: SharedApiEndpoints = {
  GET_CONCEPT_WBS: "/api/projects/getProjectWBS/{projectId}",
  ADD_PROJECT_DELIVERABLES: "/api/deliverables", // Used in Step 1 Scope and Step 2 Schedule
};

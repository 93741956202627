import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import MenuItem from "@mui/material/MenuItem";
import { FormikProps } from "formik";
import { useRef, useState } from "react";

import cloneConcept from "@/features/portfolio/api/cloneConceptService";
import { CloneModel } from "@/features/portfolio/models/cloneModel";
import ReusableDialog from "@/shared/components/ReusableDialog";

import { Concept, PortfolioRowModel } from "../models/portfolioModel";

import CloneForm from "./forms/CloneForm";

export type CloneConceptButtonProps = {
  concept: PortfolioRowModel;
  projectCode: number;
  addCloneConcept: (newConcept: Concept) => void;
  closePopover: () => void;
};

export default function CloneConceptButton({
  concept,
  projectCode,
  addCloneConcept,
  closePopover,
}: CloneConceptButtonProps) {
  const [isModalOpen, setModalOpen] = useState(false);
  const cloneConceptFormikRef = useRef<FormikProps<CloneModel>>(null);

  const handleSubmitForm = () => {
    if (isModalOpen && cloneConceptFormikRef.current) {
      void cloneConceptFormikRef.current.submitForm();
      setModalOpen(false);
    }
  };

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
    closePopover();
  };

  const cloneConceptAsync = async (newConcept: CloneModel) => {
    try {
      const clonedConcept = await cloneConcept({ ...newConcept });
      addCloneConcept(clonedConcept);
    } catch (error: unknown) {
      if (error instanceof Error) {
        throw new Error(`Error cloning concept: ${error.message}`);
      } else {
        throw new Error("Error cloning concept: An unknown error occurred");
      }
    }
  };

  const handleCloneConcept = (newConcept: CloneModel) => {
    void cloneConceptAsync(newConcept);
    handleClose();
  };

  return (
    <>
      <MenuItem
        onClick={handleOpen}
        sx={{
          fontSize: "15px !important",
        }}>
        <ContentCopyIcon fontSize="small" sx={{ marginRight: "5px" }} />
        <strong>Clone</strong>
      </MenuItem>

      <ReusableDialog
        isOpen={isModalOpen}
        title="Clone"
        cancelButtonCallback={handleClose}
        confirmButtonCallback={handleSubmitForm}
        confirmButtonText="Clone"
        confirmButtonType="primary"
        size="medium">
        <CloneForm
          portfolioItem={concept}
          projectCode={projectCode}
          onClone={handleCloneConcept}
          innerRef={cloneConceptFormikRef}
        />
      </ReusableDialog>
    </>
  );
}

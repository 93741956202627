import { Box } from "@mui/material";

import { OverviewItemsModel } from "@/shared/components/baselineOverview";

import { RNumberLabel } from "./inputs";

// Helper function
const getOverviewItemById = (id: string, items?: OverviewItemsModel[]) => {
  return items?.find((item) => item.id === id);
};

export default function Footer({
  id,
  items,
  category,
}: {
  id: string;
  items?: OverviewItemsModel[];
  category:
    | "currency"
    | "emissions"
    | "duration"
    | "percentage"
    | "quantity"
    | "outcome"
    | "per outcome";
}) {
  const overviewItem = getOverviewItemById(id, items);

  return (
    <Box
      sx={{
        color: "primary.light",
        backgroundColor: overviewItem ? overviewItem.color : "var(--ecerto-red-dark)",
        fontWeight: "bold",
        textAlign: "right",
        width: "100%",
        height: "100%",
      }}>
      <Box
        sx={{
          marginRight: "0.5rem",
        }}>
        <RNumberLabel
          value={overviewItem ? overviewItem.amount : "0"}
          category={category}
        />
      </Box>
    </Box>
  );
}

//@todo We should create 2 components, a label date, and input/label date. Making sure they have similar props
// This way we can apply this component across integrati and manage the behavior from the reusable component.
// Currently the size of date inputs or label differs depending the component, they should all be the same.
import { DateField } from "@mui/x-date-pickers/DateField";
import dayjs, { Dayjs } from "dayjs";

type RDateFieldProps = {
  date: Dayjs;
  disabled?: boolean;
  style?: React.CSSProperties;
  dateRef?: React.MutableRefObject<Dayjs | null>;
  handleBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  handleChange?: (date: Dayjs | null) => void;
  handleKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
};

export default function RDateField({
  date,
  disabled,
  style,
  dateRef,
  handleBlur,
  handleChange,
  handleKeyPress,
}: RDateFieldProps) {
  return (
    <DateField
      sx={style}
      key={date.toString()}
      format="DD/MM/YYYY"
      disabled={disabled}
      size="small"
      variant="standard"
      defaultValue={date}
      inputRef={dateRef}
      onBlur={handleBlur}
      onChange={(newDate) => handleChange?.(dayjs(newDate))}
      onKeyDown={handleKeyPress}
    />
  );
}

import { Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

import { RNumberLabel } from "@/shared/components/inputs";
import useProjectWBSContext from "@/shared/context/projectWBS/useProjectWBSContext";

export default function SidebarSummaryTable() {
  const { getWBSEntryById } = useProjectWBSContext();

  const WBSEntry = getWBSEntryById();
  // @todo if WBSEntry is null return custom message.
  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableBody
          style={{
            backgroundColor: "var(--primary-color)",
          }}>
          <TableRow
            key="estimatedCost"
            style={{
              backgroundColor: "var(--ecerto-green)",
            }}>
            <TableCell
              style={{
                color: "white",
                borderColor: "var(--primary-color)",
              }}>
              <Typography>Project</Typography>
            </TableCell>
            <TableCell
              align="right"
              style={{
                color: "white",
                borderColor: "var(--primary-color)",
              }}>
              <RNumberLabel value={WBSEntry?.costForProject} category="currency" />
            </TableCell>
          </TableRow>
          <TableRow
            key="managementReserve"
            style={{
              backgroundColor: "var(--primary-color)",
              borderColor: "var(--primary-color)",
            }}>
            <TableCell
              style={{
                color: "white",
                borderColor: "var(--primary-color)",
              }}>
              <Typography>Reserve</Typography>
            </TableCell>
            <TableCell
              align="right"
              style={{
                color: "white",
                borderColor: "var(--primary-color)",
              }}>
              <RNumberLabel value={WBSEntry?.reserveForCost} category="currency" />
            </TableCell>
          </TableRow>
          <TableRow
            key="projectBudget"
            style={{ backgroundColor: "var(--ecerto-green)" }}>
            <TableCell
              style={{
                color: "white",
                borderColor: "var(--primary-color)",
              }}>
              <Typography>Budget</Typography>
            </TableCell>
            <TableCell
              align="right"
              style={{
                color: "white",
                borderColor: "var(--primary-color)",
              }}>
              <RNumberLabel value={WBSEntry?.budgetForCost} category="currency" />
            </TableCell>
          </TableRow>
          <TableRow key="executionContingency">
            <TableCell
              style={{
                color: "white",
                borderColor: "var(--primary-color)",
              }}>
              <Typography>Contingency</Typography>
            </TableCell>
            <TableCell
              align="right"
              style={{
                color: "white",
                borderColor: "var(--primary-color)",
              }}>
              <RNumberLabel value={WBSEntry?.contingencyForCost} category="currency" />
            </TableCell>
          </TableRow>
          <TableRow
            key="costBaselineFromSimulation"
            style={{ backgroundColor: "var(--ecerto-green)" }}>
            <TableCell
              style={{
                color: "white",
                borderColor: "var(--primary-color)",
              }}>
              <Typography>Baseline</Typography>
            </TableCell>
            <TableCell
              align="right"
              style={{
                color: "white",
                borderColor: "var(--primary-color)",
              }}>
              <RNumberLabel value={WBSEntry?.baselineForCost} category="currency" />
            </TableCell>
          </TableRow>
          <TableRow key="scopeAllowance">
            <TableCell
              style={{
                color: "white",
                borderColor: "var(--primary-color)",
              }}>
              <Typography>Allowance</Typography>
            </TableCell>
            <TableCell
              align="right"
              style={{
                color: "white",
                borderColor: "var(--primary-color)",
              }}>
              <RNumberLabel value={WBSEntry?.allowanceForCost} category="currency" />
            </TableCell>
          </TableRow>
          <TableRow
            key="controlEstimate"
            style={{ backgroundColor: "var(--ecerto-green)" }}>
            <TableCell
              style={{
                color: "white",
                borderColor: "var(--primary-color)",
              }}>
              <Typography>Estimate</Typography>
            </TableCell>
            <TableCell
              align="right"
              style={{
                color: "white",
                borderColor: "var(--primary-color)",
              }}>
              <RNumberLabel value={WBSEntry?.estimateForCost} category="currency" />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

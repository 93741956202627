// @refactor find a better way to deal with empty values in textfield of type number. Alternatively avoid the transform in the yup schema and instead pass a null in the onChange event of the text field
import Box from "@mui/material/Box";
import { Formik, Form, FormikProps } from "formik";
import { RefObject } from "react";
import { useParams } from "react-router-dom";
import * as yup from "yup";

import { RiskBaseModel } from "@/features/risk/types/riskModels";
import CustomTextField from "@/shared/components/CustomTextField";
import { getSelectedCurrencyTemp } from "@/shared/utils";

import addRisk from "../api/addRisk";

const validationSchema = yup.object({
  name: yup.string().required("is required").max(50, "must be 50 characters or less"),
  probabOfOccurrence: yup
    .number()
    .nullable()
    .max(100, "exceeds the maximum value allowed (100%)")
    .transform((value: number, originalValue: string | number | null): number | null => {
      return originalValue === "" ? null : value;
    }),
  modeDuration: yup
    .number()
    .nullable()
    .max(365, "exceeds the maximum value allowed")
    .transform((value: number, originalValue: string | number | null): number | null => {
      return originalValue === "" ? null : value;
    }),
  modeRemedialCost: yup
    .number()
    .nullable()
    .max(9999999999999, "exceeds the maximum value allowed")
    .transform((value: number, originalValue: string | number | null): number | null => {
      return originalValue === "" ? null : value;
    }),
});
export type CreateNewResourceFormProps = {
  innerRef: RefObject<FormikProps<RiskBaseModel>>;
};

export default function AddRiskForm({ innerRef }: CreateNewResourceFormProps) {
  const { outcome } = useParams();
  const initialValues: RiskBaseModel = {
    id: "",
    wbsEntryId: outcome ?? "",
    name: "",
    probabOfOccurrence: null,
    modeDuration: null,
    modeRemedialCost: null,
    impactCost: 0,
    impactDuration: 0,
  };
  //@refactor The form should not handle the api call, instead it should be the AddRisk.tsx
  return (
    <Formik
      initialValues={initialValues}
      innerRef={innerRef}
      validationSchema={validationSchema}
      onSubmit={async (values, { setStatus }) => {
        try {
          await addRisk(values);
        } catch (err) {
          if (err instanceof Error) {
            setStatus({ errorMessage: err.message });

            // Set a timeout to clear the error message after 5 seconds
            setTimeout(() => setStatus(null), 5000);
          }
        }
      }}>
      {({
        setFieldTouched,
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        validateField,
      }) => (
        <Box sx={{ "& .MuiTextField-root": { mb: 2 }, paddingTop: "1rem" }}>
          <Form>
            <CustomTextField
              name="name"
              label="Resource Name"
              type="string"
              value={values.name}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldTouched={setFieldTouched}
              validateField={validateField}
              maxLength={50}
              required
            />

            <CustomTextField
              name="probabOfOccurrence"
              label="Residual Probability"
              type="number"
              value={values.probabOfOccurrence}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldTouched={setFieldTouched}
              validateField={validateField}
              maxLength={3}
              InputProps="%"
            />
            <CustomTextField
              name="modeDuration"
              label="Duration"
              type="number"
              value={values.modeDuration}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldTouched={setFieldTouched}
              validateField={validateField}
              maxLength={3}
              InputProps="days"
            />
            <CustomTextField
              name="modeRemedialCost"
              label="Remedial Cost"
              type="number"
              value={values.modeRemedialCost}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldTouched={setFieldTouched}
              validateField={validateField}
              maxLength={13}
              InputProps={getSelectedCurrencyTemp()}
            />

            {/* <Box display="flex" justifyContent="flex-end" mt={1} gap={1}>
              <Button variant="outlined" onClick={onClose}>
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                disabled={isSubmitting || !isValid}>
                Create
              </Button>
            </Box> */}
          </Form>
        </Box>
      )}
    </Formik>
  );
}

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Formik, Form } from "formik";
import * as yup from "yup";

import { addUserFormStyles } from "@/features/tenantManagement/styles/addUserFormStyles";
import RListButton from "@/shared/components/buttons/RListButton";
import { Roles } from "@/shared/enums/roles";

const validationSchema = yup.object({
  emailAddress: yup.string().email(),
  role: yup
    .string()
    .oneOf(Object.values(Roles), "Please select a valid role")
    .required("Role is required"),
});

export default function InviteUserForm() {
  const roles = Object.values(Roles).map((role) => ({
    value: role,
    label: role,
  }));

  return (
    <Formik
      initialValues={{
        emailAddress: "email@ecerto.io",
        role: Roles.Member,
      }}
      validationSchema={validationSchema}
      onSubmit={() => {
        try {
          //   onConfirm(values);
        } catch (err) {
          if (err instanceof Error) {
            throw new Error(`Failed to confirm user: ${err.message}`);
          } else {
            throw new Error("Failed to confirm user: An unknown error occurred");
          }
        }
      }}>
      {({ values, errors, touched, handleChange, handleBlur }) => (
        <Box sx={{ "& .MuiTextField-root": { mb: 2 }, paddingTop: "1rem" }}>
          <Form>
            <TextField
              required
              name="emailAddress"
              label="Email"
              variant="filled"
              sx={addUserFormStyles.textfield}
              value={values.emailAddress}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.emailAddress && Boolean(errors.emailAddress)}
              fullWidth
            />
            <TextField
              required
              select
              name="role"
              label="Role"
              variant="filled"
              sx={addUserFormStyles.textfield}
              value={values.role}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.role && Boolean(errors.role)}
              helperText={touched.role && errors.role}
              fullWidth>
              {roles.map(({ value, label }) => (
                <RListButton key={value} value={value} isMenuItem>
                  {label}
                </RListButton>
              ))}
            </TextField>
          </Form>
        </Box>
      )}
    </Formik>
  );
}

import { AxiosError, AxiosResponse } from "axios";

import { RISK_API_ENDPOINTS } from "@/features/risk/api/endpoints";
import apiRequests from "@/shared/config/axiosConfig";

type DeleteRiskResponse = {
  message: string;
  statusCode: number;
};

export default async function deleteRisk(riskId: string): Promise<DeleteRiskResponse> {
  try {
    const url = RISK_API_ENDPOINTS.DELETE_RISK.replace("{riskId}", riskId);

    const response: AxiosResponse<DeleteRiskResponse> = await apiRequests.delete(url);

    const { data, status } = response;

    if (status >= 200 && status < 300) {
      return data;
    }

    throw new Error(`Unknown error occurred with status code ${response.status}`);
  } catch (e) {
    // @todo Handle Error Below
    const error = e as AxiosError;
    switch (error.response?.status) {
      case 400:
        throw new Error("400: Bad Request");
      case 401:
        throw new Error("401: Unauthorized");
      case 404:
        throw new Error("404: Not Found");
      default:
        throw new Error(
          `Unknown error occurred with status code ${error.response?.status}`
        );
    }
  }
}

import { AxiosError } from "axios";

import { SHARED_API_ENDPOINTS } from "@/shared/api/endpoints";
import apiRequests from "@/shared/config/axiosConfig";
import {
  WBSEntryModel,
  WBSEntryViewModel,
} from "@/shared/context/projectWBS/projectWBSModel";

/**
 * Edits a deliverable in the project scope.
 * @param {WBSEntryModel} deliverable - The deliverable to be edited.
 * @returns {Promise<WBSEntryModel[]>} - A promise that resolves with an array of ProjectScopeModel objects.
 * @throws {Error} - Throws an error with a message indicating the type of error that occurred.
 */
const editDeliverable = async (
  deliverable: WBSEntryModel | WBSEntryViewModel
): Promise<WBSEntryModel[] | WBSEntryViewModel[]> => {
  try {
    const url = SHARED_API_ENDPOINTS.ADD_PROJECT_DELIVERABLES;

    const response = await apiRequests.put(url, deliverable);

    if (response.status >= 200 && response.status < 300) {
      return response.data as WBSEntryModel[];
    }

    throw new Error(`Unknown error occurred with status code ${response.status}`);
  } catch (e) {
    // @todo Handle Error Below
    const error = e as AxiosError;
    switch (error.response?.status) {
      case 400:
        throw new Error("400: Bad Request");
      case 401:
        throw new Error("401: Unauthorized");
      case 404:
        throw new Error("404: Not Found");
      default:
        throw new Error(
          `Unknown error occurred with status code ${error.response?.status}`
        );
    }
  }
};
export default editDeliverable;

import Box from "@mui/system/Box";
import dayjs, { Dayjs } from "dayjs";
import { useRef } from "react";

import { formatDateToAvoidTimezoneIssues } from "@/features/projectSchedule/helpers/helper";
import { BoxStyle, DateFieldStyle } from "@/features/projectSchedule/styles/styles";
import { DateCellTypes } from "@/features/projectSchedule/types/types";
import { RDateField } from "@/shared/components/inputs";

export default function DateCell({ scheduleEntry, onDateChange, type }: DateCellTypes) {
  const isStartDateEdited = type === "start";
  const initialDate = dayjs(isStartDateEdited ? scheduleEntry.start : scheduleEntry.end);
  const dateRef = useRef<Dayjs>(dayjs(initialDate));

  const handleBlur = () => {
    const dateEdited = dateRef.current;
    // If the new date is the same as the current date, do nothing
    if (dateEdited.isSame(initialDate)) return;

    let newStartDate;
    if (isStartDateEdited) {
      newStartDate = dateEdited;
    } else {
      newStartDate = dateEdited.subtract(scheduleEntry.duration ?? 0, "day");
    }
    const startDateAsDate = newStartDate.toDate();
    const timezoneSafeDate = formatDateToAvoidTimezoneIssues(startDateAsDate);
    const startDate = new Date(timezoneSafeDate);

    const newWBSEntry = { ...scheduleEntry, start: startDate };
    void onDateChange(newWBSEntry, "FromTable");
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      handleBlur();
    }
  };

  return (
    <Box sx={BoxStyle}>
      <RDateField
        style={DateFieldStyle}
        date={initialDate}
        disabled={scheduleEntry.isDisabled}
        dateRef={dateRef}
        handleBlur={handleBlur}
        handleChange={(newDate) => {
          dateRef.current = dayjs(newDate as unknown as string);
        }}
        handleKeyPress={handleKeyPress}
      />
    </Box>
  );
}

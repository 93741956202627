import Typography, { TypographyProps } from "@mui/material/Typography";
import { MRT_Cell, MRT_Row, type MRT_ColumnDef } from "material-react-table";
import { useMemo } from "react";

import DropdownUnit from "@/features/parametric/components/DropdownUnit";
import { capitalizeFirstLetter } from "@/features/parametric/helpers/helper";
import { RNumberInputLabel } from "@/shared/components/inputs";
import ParametricCostTypes from "@/shared/enums/parametricCostTypes";
import { UnitsAbbreviations } from "@/shared/enums/units";

import {
  ParameterModels,
  ParameterProbabModel,
  ParameterScenariosModel,
} from "../../../shared/types/parameterModels";

// Import your models
type HandleSaveCellType = (
  cell: MRT_Cell<ParameterModels>,
  newUnit: number
) => Promise<void>;
export type ParametricTableColumnProps = {
  parameters: ParameterModels[];
  handleSaveCell: HandleSaveCellType;
};

// Base columns for ParameterDeterModel
const getDeterModelColumns = (
  handleSaveCell: HandleSaveCellType
): MRT_ColumnDef<ParameterModels>[] => {
  // Main function to generate columns
  return [
    {
      accessorKey: "name",
      header: "Parameter",
      minSize: 90,
      size: 90,
      maxSize: 100,
      enableEditing: false,
      muiTableBodyCellProps: {
        align: "left",
      },
      Cell: ({ cell }) => {
        return (
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            {`${capitalizeFirstLetter(ParametricCostTypes[cell.getValue<number>()])}`}
          </Typography>
        );
      },
    },
    {
      accessorKey: "unit",
      header: "Unit",
      minSize: 90,
      size: 90,
      maxSize: 100,
      enableClickToCopy: false,
      enableEditing: false,
      muiTableBodyCellProps: {
        align: "left",
      },
      Cell: ({
        cell,
        row,
      }: {
        cell: MRT_Cell<ParameterModels>;
        row: MRT_Row<ParameterModels>;
      }) => {
        const parameterName = row.original.name; // To know which units to render
        // Callback function to update the cell data
        const handleUnitChange = (newUnit: number) => {
          void handleSaveCell(cell, newUnit);
        };
        return (
          <DropdownUnit
            selectedValue={cell.getValue() as number}
            parameterName={parameterName}
            onUnitChanged={handleUnitChange}
          />
        );
      },
    },
    {
      accessorKey: "quantity",
      header: "Quantity",
      size: 30,
      enableEditing: false,
      enableClickToCopy: false,
      muiTableBodyCellProps: {
        align: "right",
      },
      Cell: ({
        cell,
        row,
      }: {
        cell: MRT_Cell<ParameterModels>;
        row: MRT_Row<ParameterModels>;
      }) => {
        const unit = UnitsAbbreviations.get(row.original.unit);
        const handleUnitChange = (newUnit: number) => {
          void handleSaveCell(cell, newUnit);
        };
        const getTypographyProps: TypographyProps = {
          variant: "h6",
          fontWeight: 500,
        };
        const category = ParametricCostTypes[row.original.name].toLowerCase() as
          | "outcome"
          | "emissions"
          | "duration";
        return (
          <RNumberInputLabel
            value={row.original.quantity}
            category={category}
            customUnit={unit}
            onInputChange={handleUnitChange}
            typographyProps={getTypographyProps}
          />
        );
      },
    },
  ];
};
// Additional columns for ParameterProbabModel
const getProbabModelColumns = (): MRT_ColumnDef<ParameterModels>[] => {
  return [
    {
      accessorKey: "min",
      header: "Min",
      minSize: 90,
      size: 90,
      maxSize: 100,
      muiTableBodyCellProps: {
        align: "center",
      },
      Cell: ({ cell }) => (
        <div
          style={{
            fontWeight: "inherit",
          }}>
          {`${cell.getValue<number>()}`}
        </div>
      ),
    },
    {
      accessorKey: "max",
      header: "Max",
      minSize: 90,
      size: 90,
      maxSize: 100,
      muiTableBodyCellProps: {
        align: "center",
      },
      Cell: ({ cell }) => (
        <div
          style={{
            fontWeight: "inherit",
          }}>
          {`${cell.getValue<number>()}`}
        </div>
      ),
    },
    {
      accessorKey: "probabGenericBaselineDistrib",
      header: "Probabilistic Generic Baseline",
      minSize: 90,
      size: 90,
      maxSize: 100,
      enableEditing: false,
      muiTableBodyCellProps: {
        align: "center",
      },
      Cell: ({ cell }) => (
        <div
          style={{
            fontWeight: "inherit",
          }}>
          {`${cell.getValue<number>().toFixed(2)}`}
        </div>
      ),
    },
  ];
};

// Additional columns for ParameterScenariosModel
const getScenariosModelColumns = (): MRT_ColumnDef<ParameterModels>[] => {
  return [
    {
      accessorKey: "selectedBaselinePercentile",
      header: "Selected Baseline Percentile",
      minSize: 90,
      size: 90,
      maxSize: 100,
      enableEditing: false,
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      accessorKey: "probabBaselineDistrib",
      header: "Probabilistic Baseline",
      minSize: 90,
      size: 90,
      maxSize: 100,
      enableEditing: false,
      muiTableBodyCellProps: {
        align: "center",
      },
    },
  ];
};

// Helper function to determine if the model is of type ParameterProbabModel
const isProbabModel = (models: ParameterModels[]): models is ParameterProbabModel[] => {
  return models.length > 0 && "probabGenericBaselineDistrib" in models[0];
};

// Helper function to determine if the models is of type ParameterScenariosModel
const isScenariosModel = (
  models: ParameterModels[]
): models is ParameterScenariosModel[] => {
  return models.length > 0 && "probabBaselineDistrib" in models[0];
};

/**
 * The function checks the type of each model and returns the appropriate columns for that model.
 * Depending on whether the model is deterministic, probabilistic or scenario, different columns will be returned.
 * Function to merge columns based on the models type
 */
const ColumnsForParametricTable = ({
  parameters,
  handleSaveCell,
}: ParametricTableColumnProps) =>
  useMemo<MRT_ColumnDef<ParameterModels>[]>(() => {
    let columns = getDeterModelColumns(handleSaveCell); // Start with base columns

    if (isProbabModel(parameters)) {
      columns = columns.concat(getProbabModelColumns());
    }

    if (isScenariosModel(parameters)) {
      columns = columns.concat(getScenariosModelColumns());
    }
    return columns;
  }, [parameters, handleSaveCell]);

export default ColumnsForParametricTable;

import { Box, Paper, Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
// import { color } from "d3";

import { BaselineOverviewModel } from "../model/baselineOverviewModels";

import BaselineOverviewSkeleton from "./BaselineOverviewSkeleton";
import {
  getBaselineOverviewStyles,
  getOverviewItemStyles,
} from "./baselineOverviewStyles";
import OverviewItem from "./OverviewItem";

export default function BaselineOverview({
  OverviewItems,
  title,
  isLoading,
}: BaselineOverviewModel) {
  const theme = useTheme();
  const { typographyTitleStyle } = getOverviewItemStyles(theme);

  if (!OverviewItems) {
    return null;
  }

  const {
    containerBaselineOverviewStyle,
    divBaselineOverviewStyle,
    titleBaselineOverviewStyle,
    titleLineStyle,
    bottomLineStyle,
  } = getBaselineOverviewStyles(theme);

  if (isLoading) {
    return <BaselineOverviewSkeleton />;
  }

  return (
    <Box
      sx={{
        position: "relative",
        "&::after": {
          content: '""',
          position: "absolute",
          top: 0,
          right: 0,
          width: "100px",
          height: "100%",
          backgroundImage: "linear-gradient(to right, transparent 0%, white 6rem)",
          pointerEvents: "none",
          [theme.breakpoints.up(1250)]: {
            display: "none",
          },
        },
        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          width: "100px",
          height: "100%",
          backgroundImage: "linear-gradient(to left, transparent 0%, white 6rem)",
          pointerEvents: "none",
          zIndex: 1,
          [theme.breakpoints.up(1250)]: {
            display: "none",
          },
        },
      }}>
      <Paper
        elevation={4}
        sx={{
          ...containerBaselineOverviewStyle,
          overflowX: "auto",
        }}>
        <Grid
          container
          sx={{
            overflowX: "auto",
            minWidth: "750px",
          }}>
          <Grid item xs={12}>
            <div style={divBaselineOverviewStyle}>
              <Typography style={titleBaselineOverviewStyle}>{title}</Typography>
              <div style={titleLineStyle} />
            </div>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            sx={{ padding: "0rem 3rem 0.5rem 3rem", flexWrap: "nowrap" }}>
            <Grid item sx={{ width: "7rem" }} />
            <Grid item sx={{ width: "17rem" }}>
              <Typography sx={typographyTitleStyle}>Parametric</Typography>
            </Grid>
            <Grid item sx={{ width: "11rem" }}>
              <Typography style={typographyTitleStyle}>Cost</Typography>
            </Grid>
            <Grid item sx={{ width: "8rem" }}>
              <Typography style={typographyTitleStyle}>Duration</Typography>
            </Grid>
            <Grid item sx={{ width: "9rem" }}>
              <Typography style={typographyTitleStyle}>Emissions</Typography>
            </Grid>
          </Grid>

          <Grid
            container
            justifyContent="space-between"
            sx={{ padding: "0rem 3rem 0.3rem 3rem", flexWrap: "nowrap" }}>
            <Grid item>
              <Typography
                sx={{ ...typographyTitleStyle, textAlign: "left", width: "7rem" }}>
                Estimate
              </Typography>
            </Grid>
            <OverviewItem item={OverviewItems[0]} type="estimate" />
            <OverviewItem item={OverviewItems[1]} />
            <OverviewItem item={OverviewItems[2]} />
            <OverviewItem item={OverviewItems[3]} />
          </Grid>

          <Grid
            container
            justifyContent="space-between"
            sx={{ padding: "0rem 3rem 0rem 3rem", flexWrap: "nowrap" }}>
            <Grid item>
              <Typography
                sx={{ ...typographyTitleStyle, textAlign: "left", width: "7rem" }}>
                Risk
              </Typography>
            </Grid>
            <OverviewItem item={OverviewItems[4]} type="risk" />
            <OverviewItem item={OverviewItems[5]} />
            <OverviewItem item={OverviewItems[6]} />
            <OverviewItem item={OverviewItems[7]} />
          </Grid>
          <div style={bottomLineStyle} />
        </Grid>
      </Paper>
    </Box>
  );
}

export const ScheduleCircularProgress = () => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "35vh",
});

export const BoxStyle = () => ({
  padding: "0px",
  border: "none",
  marginRight: "1.25rem",
});

export const GanttTableBoxStyle = () => ({
  display: "table-header-group",
  width: "37.5rem",
});

export const DateFieldStyle = {
  width: "5.3125rem",
  whiteSpace: "nowrap",
  "& .MuiInputBase-input": {
    textAlign: "right",
  },
  "& .Mui-disabled": {
    "& .MuiInputBase-input": {
      WebkitTextFillColor: "rgba(0, 48, 87, 0.8)",
    },
  },
  "& .MuiInputBase-root.Mui-disabled:before": {
    border: "none",
  },
  "& .MuiInputBase-root:before": {
    borderBottom: "0.15rem solid var(--primary-color-100)",
  },
  "& .MuiFormHelperText-root": {
    fontSize: "0.7rem",
    marginTop: "0px",
    marginBottom: "0px",
  },
};

export const NameCellBoxStyle = (paddingLeft: number) => ({
  display: "flex",
  marginRight: "0.625rem",
  border: "none",
  padding: "0px",
  alignItems: "center",
  width: "18.125rem",
  paddingLeft: `${paddingLeft / 16}rem`,
});

export const NameCellTypographyStyle = () => ({
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

export const NameCellButtonStyle = () => ({
  marginRight: 0,
  "&.MuiButtonBase-root.MuiButton-root": {
    minWidth: "1.5625rem !important",
  },
});

export const DimensionsMenuStyle = () => ({
  ".MuiPaper-root": {
    borderColor: "#e0e0e0",
    borderWidth: "0.0625rem",
    borderStyle: "solid",
    width: "15.625rem",
    padding: "0.625rem",
    boxShadow:
      "0px 0.1875rem 0.3125rem -0.0625rem rgba(0,0,0,0.1), 0px 0.375rem 0.625rem 0px rgba(0,0,0,0.08), 0px 0.0625rem 1.125rem 0px rgba(0,0,0,0.06)",
  },
});

export const DisplayOptionsStyle = () => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: "2.1875rem",
  marginBottom: "0.5rem",
  marginRight: "0.5rem",
  gap: "0.375rem",
});

export const SliderStyle = () => ({
  width: "15.625rem",
  marginTop: "0.5rem",
});

export const TimeFrameMenuStyle = () => ({
  PaperProps: {
    elevation: 6,
    style: {
      borderColor: "#e0e0e0",
      borderWidth: "0.0625rem",
      borderStyle: "solid",
      minWidth: "12.5rem",
      padding: "0.25rem",
      boxShadow:
        "0px 0.1875rem 0.3125rem -0.0625rem rgba(0,0,0,0.1), 0px 0.375rem 0.625rem 0px rgba(0,0,0,0.08), 0px 0.0625rem 1.125rem 0px rgba(0,0,0,0.06)",
    },
  },
});

export const TimeFrameSelectStyle = () => ({
  backgroundColor: "none",
  height: "2.1875rem",
  color: "rgba(0, 0, 0, 0.54)",
  fontSize: "1.25rem",
  fontWeight: "medium",
  cursor: "pointer",
  border: "none",
  ".MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "&:hover": {
    backgroundColor: "#ebebeb",
    transition: "transform 0.5s ease",
    transform: "scale(1.1)",
  },
});

export const ContainerSchedule = () => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
});

export const DisplayOptionsSchedule = () => ({
  order: 1,
});

export const WrapperSchedule = () => ({
  order: 2,
  width: "100%",
});

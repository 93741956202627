import { AxiosError, AxiosResponse } from "axios";

import { COST_API_ENDPOINTS } from "@/features/cost/api/endpoints";
import apiRequests from "@/shared/config/axiosConfig";

import { CostModelForProjectBaseline } from "../types/costModelForProjectBaseline";

export default async function editCost(body: {
  resourceId: string;
  deliverableId: string;
  quantity: number;
  distribution: string;
  upperBound: number;
  lowerBound: number;
  allocatedQuantity: number;
  selectedBaseline: number;
  resetAllocations: boolean;
}): Promise<CostModelForProjectBaseline> {
  try {
    const url = COST_API_ENDPOINTS.EDIT_COST;

    const response: AxiosResponse<CostModelForProjectBaseline> = await apiRequests.patch(
      url,
      body
    );

    const { data, status } = response;

    if (status >= 200 && status < 300) {
      return data;
    }

    throw new Error(`Unknown error occurred with status code ${response.status}`);
  } catch (e) {
    // @todo Handle Error Below
    const error = e as AxiosError;
    switch (error.response?.status) {
      case 400:
        throw new Error("400: Bad Request");
      case 401:
        throw new Error("401: Unauthorized");
      case 404:
        throw new Error("404: Not Found");
      default:
        throw new Error(
          `Unknown error occurred with status code ${error.response?.status}`
        );
    }
  }
}

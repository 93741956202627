import "../../styles/styles.css";
import Box from "@mui/system/Box";

import DateCell from "@/features/projectSchedule/components/ganttTable/DateCell";
import DurationCell from "@/features/projectSchedule/components/ganttTable/DurationCell";
import HeadCell from "@/features/projectSchedule/components/ganttTable/HeadCell";
import NameCell from "@/features/projectSchedule/components/ganttTable/NameCell";
import ScheduleTableRow from "@/features/projectSchedule/components/ganttTable/ScheduleTableRow";
import { GanttTableBoxStyle } from "@/features/projectSchedule/styles/styles";
import {
  IScheduleTableHeader,
  IScheduleTableBody,
  ScheduleTableTypes,
} from "@/features/projectSchedule/types/types";
import { IScheduleItems } from "@/shared/types/scheduleItems";

export default function ScheduleTable({ onGanttBoardChange }: ScheduleTableTypes) {
  const ScheduleTableHeader = ({
    headerHeight,
    fontFamily,
    fontSize,
  }: IScheduleTableHeader) => {
    return (
      <Box
        sx={{
          GanttTableBoxStyle,
        }}>
        <ScheduleTableRow
          height={headerHeight}
          fontFamily={fontFamily}
          fontSize={fontSize}
          fontColor="#ffffff"
          alignment="flex-end">
          <HeadCell width="17.8125rem">Name</HeadCell>
          <HeadCell width="6.5625rem">Duration</HeadCell>
          <HeadCell width="6.6875rem">Start Date</HeadCell>
          <HeadCell width="7.25rem">End Date</HeadCell>
        </ScheduleTableRow>
      </Box>
    );
  };

  const ScheduleTableBody = ({
    tasks: scheduleEntries,
    onExpanderClick,
    fontFamily,
    fontSize,
    rowHeight,
  }: IScheduleTableBody) => {
    return (
      <Box>
        {scheduleEntries.map((scheduleEntry: IScheduleItems) => {
          return (
            <ScheduleTableRow
              key={scheduleEntry.id}
              wbsEntryLevel={scheduleEntry.level}
              height={rowHeight}
              fontFamily={fontFamily}
              fontSize={fontSize}>
              <NameCell scheduleEntry={scheduleEntry} onExpanderClick={onExpanderClick} />
              <DurationCell
                scheduleEntry={scheduleEntry}
                onDurationChange={onGanttBoardChange}
              />
              <DateCell
                scheduleEntry={scheduleEntry}
                onDateChange={onGanttBoardChange}
                type="start"
              />
              <DateCell
                scheduleEntry={scheduleEntry}
                onDateChange={onGanttBoardChange}
                type="end"
              />
            </ScheduleTableRow>
          );
        })}
      </Box>
    );
  };

  return {
    ScheduleTableHeader,
    ScheduleTableBody,
  };
}

import { Box, Typography } from "@mui/material";
import { MRT_Cell, MRT_Row, type MRT_ColumnDef } from "material-react-table";
import { useMemo } from "react";

import { OverviewItemsModel } from "@/shared/components/baselineOverview/model/baselineOverviewModels";
import Footer from "@/shared/components/FooterForColumns";
import { RNumberInputLabel, RNumberLabel } from "@/shared/components/inputs";

import { CostModelForProjectBaseline } from "../types/costModelForProjectBaseline";

type HandleSaveCellType = (
  cell: MRT_Cell<CostModelForProjectBaseline>,
  newValue: number
) => Promise<void>;
const ColumnsCostForProjectBaseline = (
  OverviewItems: OverviewItemsModel[],
  handleSaveCell: HandleSaveCellType
) => {
  return useMemo<MRT_ColumnDef<CostModelForProjectBaseline>[]>(
    () => [
      {
        accessorKey: "category",
        header: "Category",
        minSize: 90,
        size: 275,
        maxSize: 275,
        enableEditing: false,
        muiTableBodyCellProps: {
          align: "left",
        },
        accessorFn: (row: CostModelForProjectBaseline) =>
          `${String(row.category)} ${String(row.subCategory)}`,

        Cell: ({ row }) => (
          <span>
            <Typography
              style={{
                fontWeight: "bold",
              }}>
              {row.original.category}
            </Typography>
            <Typography
              variant="body2"
              style={{
                color: "var(--ecerto-cool-grey-dark)",
              }}>{`${String(row.original.subCategory)}`}</Typography>
          </span>
        ),
      },
      {
        accessorKey: "name",
        header: "Resource Name",
        minSize: 90,
        size: 250,
        maxSize: 250,
        enableEditing: false,
        muiTableBodyCellProps: {
          align: "left",
        },
        accessorFn: (row: CostModelForProjectBaseline) =>
          `${String(row.name)} ${String(row.supplier)}`,
        Cell: ({ row }) => (
          <span>
            <Typography
              style={{
                fontWeight: "bold",
              }}>
              {row.original.name as React.ReactNode}
            </Typography>
            <Typography
              variant="body2"
              style={{
                color: "var(--ecerto-cool-grey-dark)",
              }}>{`by ${String(row.original.supplier)}`}</Typography>
          </span>
        ),
      },
      {
        accessorKey: "unit",
        header: "Unit",
        minSize: 90,
        size: 100,
        maxSize: 150,
        enableEditing: false,
        muiTableBodyCellProps: {
          align: "left",
        },
        Cell: ({ row }) => <Typography>{row.original.unit}</Typography>,
      },
      {
        accessorKey: "fixedCost",
        header: "Fixed Price",
        minSize: 90,
        size: 150,
        maxSize: 150,
        enableEditing: false,
        muiTableBodyCellProps: {
          align: "right",
        },
        Cell: ({ row }: { row: MRT_Row<CostModelForProjectBaseline> }) => {
          return (
            <RNumberLabel
              value={row.original.fixedCost as number}
              category={"currency"}
            />
          );
        },
      },
      {
        accessorKey: "quantity",
        header: "Quantity",
        size: 100,
        enableEditing: false,
        enableClickToCopy: false,
        muiTableBodyCellProps: {
          align: "right",
        },
        Cell: ({
          row,
          cell,
        }: {
          row: MRT_Row<CostModelForProjectBaseline>;
          cell: MRT_Cell<CostModelForProjectBaseline>;
        }) => {
          return (
            <RNumberInputLabel
              value={row.original.quantity}
              category={"quantity"}
              onInputChange={(newValue: number) => {
                void handleSaveCell(cell, newValue);
              }}
              decimalScaleInput={6}
              decimalScaleLabel={2}
            />
          );
        },
      },
      {
        accessorKey: "deterministicFixedCost",
        header: "Cost",
        minSize: 90,
        size: 120,
        maxSize: 150,
        enableEditing: false,
        muiTableBodyCellProps: {
          align: "right",
        },
        Cell: ({ row }: { row: MRT_Row<CostModelForProjectBaseline> }) => {
          return (
            <Box
              sx={{
                marginRight: "0.5rem",
              }}>
              <RNumberLabel
                value={row.original.deterministicFixedCost as number}
                category={"currency"}
              />
            </Box>
          );
        },
        Footer: () => (
          <Footer
            id="deterministicFixedCost"
            items={OverviewItems}
            category={"currency"}
          />
        ),
      },
    ],
    [OverviewItems, handleSaveCell]
  );
};

export default ColumnsCostForProjectBaseline;

import { SxProps } from "@mui/material";
import { Theme } from "@mui/material/styles";

export const BoxStyle = {
  width: "90%",
  margin: "auto",
};

export const TabsStyle = {
  borderBottom: 1,
  borderColor: "divider",
};

export const CustomTabPanelStyle = {
  marginTop: "2rem",
};

type ChipType = "lite" | "pro" | "enterprise";

// Reusable function to create Chip styles with type definitions
export const getChipStyle = (theme: Theme, type: ChipType): SxProps<Theme> => {
  const commonStyles = {
    borderRadius: "0.25rem",
    marginLeft: "0.2rem",
    fontSize: "0.6rem",
    height: "1.75rem",
  };

  switch (type) {
    case "lite":
      return {
        ...commonStyles,
        backgroundColor: "var(--ecerto-pantone-663)",
        color: "var(--primary-color)",
        fontWeight: "bold",
      };
    case "pro":
      return {
        ...commonStyles,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        width: "4rem",
      };
    case "enterprise":
      return {
        ...commonStyles,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        width: "7rem",
      };
    default:
      return {};
  }
};

import ModeEditIcon from "@mui/icons-material/Edit";
import MenuItem from "@mui/material/MenuItem";
import { FormikProps } from "formik";
import { MRT_Row } from "material-react-table";
import { useRef, useState } from "react";

import ReusableDialog from "@/shared/components/ReusableDialog";

import EditResourceCatalogueForm from "../forms/EditResourceCatalogueForm";
import { ResourcesModel } from "../types/resourcesModel";

type EditResourceCatalogueButtonProps = {
  row: MRT_Row<ResourcesModel>;
  onCatalogueAndCostRefetch: () => void;
};

export default function EditResourceCatalogueButton({
  row,
  onCatalogueAndCostRefetch,
}: EditResourceCatalogueButtonProps) {
  const [isModalOpen, setModalOpen] = useState(false);
  const editFormikRef = useRef<FormikProps<ResourcesModel>>(null);

  const handleSubmitForm = () => {
    if (isModalOpen && editFormikRef.current) {
      void editFormikRef.current.submitForm();
      setModalOpen(false);
    }
  };

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <MenuItem
        key="edit"
        onClick={handleOpen}
        sx={{
          fontSize: "15px",
        }}>
        <ModeEditIcon fontSize="small" sx={{ marginRight: "5px" }} />
      </MenuItem>
      <ReusableDialog
        isOpen={isModalOpen}
        title="Edit Resource Catalogue"
        confirmButtonCallback={handleSubmitForm}
        cancelButtonCallback={handleClose}
        confirmButtonText="Confirm"
        confirmButtonType="primary"
        size="medium">
        <EditResourceCatalogueForm
          row={row.original}
          onClose={handleClose}
          onCatalogueAndCostRefetch={onCatalogueAndCostRefetch}
          innerRef={editFormikRef}
        />
      </ReusableDialog>
    </>
  );
}

type DeliverablesApiEndpoints = {
  GET_DELIVERABLES_FOR_CLONE: string;
  GET_DELIVERABLE_CHILDREN: string;
  GET_DELIVERABLE_BY_ID: string;
  GET_PROJECT_DELIVERABLES: string;
  ADD_CLONED_DELIVERABLE: string;
  UPDATE_DELIVERABLE_ORDER: string;
  GET_PROJECTS_FOR_CLONE: string;
};
export const DELIVERABLES_API_ENDPOINTS: DeliverablesApiEndpoints = {
  GET_DELIVERABLES_FOR_CLONE: "/api/deliverables/projectForCloning/{programid}",
  GET_DELIVERABLE_CHILDREN: "/api/deliverables/childrenDeliverables/{deliverableid}",
  GET_DELIVERABLE_BY_ID: "/api/deliverables/{id}",
  GET_PROJECT_DELIVERABLES: "/api/deliverables/project/{projectid}",
  ADD_CLONED_DELIVERABLE: "/api/deliverables/clone",
  UPDATE_DELIVERABLE_ORDER: "/api/deliverables/order/{projectid}",
  GET_PROJECTS_FOR_CLONE: "/api/projects/getProjectsForClone",
};

import { Typography } from "@mui/material";
import { type MRT_ColumnDef } from "material-react-table";
import { useMemo } from "react";

import { ResourcesModel } from "@/features/cost/resourceCatalogue/types/resourcesModel";
import { RNumberLabel } from "@/shared/components/inputs";

const ResourceCatalogueTableColumns = () =>
  useMemo<MRT_ColumnDef<ResourcesModel>[]>(() => {
    return [
      {
        accessorKey: "category",
        header: "Category",
        minSize: 90,
        size: 275,
        maxSize: 275,
        enableEditing: false,
        muiTableBodyCellProps: {
          align: "left",
        },
        accessorFn: (row: ResourcesModel) => `${row.category} ${row.subCategory}`,
        Cell: ({ row }) => (
          <span>
            <Typography
              style={{
                fontWeight: "bold",
              }}>
              {row.original.category}
            </Typography>
            <Typography
              style={{
                fontSize: "12px",
                color: "var(--ecerto-cool-grey-dark)",
              }}>{`${row.original.subCategory}`}</Typography>
          </span>
        ),
      },
      {
        accessorKey: "name",
        header: "Resource Name",
        minSize: 90,
        size: 250,
        maxSize: 250,
        enableEditing: false,
        muiTableBodyCellProps: {
          align: "left",
        },
        accessorFn: (row: ResourcesModel) => `${row.name} ${row.supplier}`,
        Cell: ({ row }) => (
          <span>
            <Typography
              style={{
                fontWeight: "bold",
              }}>
              {row.original.name}
            </Typography>
            <Typography
              style={{
                fontSize: "12px",
                color: "var(--ecerto-cool-grey-dark)",
              }}>{`by ${row.original.supplier}`}</Typography>
          </span>
        ),
      },
      {
        accessorKey: "description",
        header: "Description",
        size: 500,
        enableEditing: false,
        muiTableBodyCellProps: {
          align: "left",
        },
        Cell: ({ row }) => <Typography>{row.original.description}</Typography>,
      },
      {
        accessorKey: "unit",
        header: "Unit",
        minSize: 90,
        size: 90,
        maxSize: 100,
        enableClickToCopy: false,
        enableEditing: false,
        muiTableBodyCellProps: {
          align: "left",
        },
        Cell: ({ row }) => <Typography>{row.original.unit}</Typography>,
      },
      {
        accessorKey: "fixedCost",
        header: "Fixed Price",
        minSize: 90,
        size: 150,
        maxSize: 150,
        enableEditing: false,
        muiTableBodyCellProps: {
          align: "right",
        },
        Cell: ({ row }) => (
          <RNumberLabel value={row.original.fixedCost} category="currency" />
        ),
      },
    ];
  }, []);

export default ResourceCatalogueTableColumns;

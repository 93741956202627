import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid2 from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import React from "react";

import { SideMenu } from "@/features/tenantManagement";
import { useTenantManagement } from "@/shared/context/tenantManagementCtx/useManagementTenant";
import { User } from "@/shared/types/userInfoModels";

type TenantManagementProps = {
  children?: React.ReactNode;
  user: User;
};

const useStyles = {
  closeButton: {
    color: "#003057",
    position: "absolute",
    top: "0.3rem",
    right: "0.1rem",
  },
  paper: {
    position: "absolute" as const,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "2.5rem",
    width: "80rem",
    height: "55rem",
    bgcolor: "var(--background-color)",
  },
};

export default function TenantManagementLayout({
  children,
  user,
}: TenantManagementProps) {
  const { activeContent, closeModal } = useTenantManagement();

  return (
    <Paper elevation={24} square={false} sx={useStyles.paper}>
      <Button variant="text" size="large" onClick={closeModal} sx={useStyles.closeButton}>
        <CloseIcon fontSize="large" />
      </Button>
      {/* Outer Most grid */}
      <Grid2 container>
        {/* Side menu grid */}
        <Grid2 size={3.2}>
          <SideMenu user={user} />
        </Grid2>

        {/* Divider grid */}
        <Grid2 size={0.6} container justifyContent="center">
          <Divider
            orientation="vertical"
            sx={{ borderWidth: "0.12rem" }}
            variant="middle"
            flexItem
          />
        </Grid2>

        {/* Main content outer grid */}
        <Grid2 size={8.2}>
          {/* Title grid */}
          <Grid2 sx={{ marginBottom: "3rem" }}>
            <Typography variant="h1" sx={{ marginTop: "1.5rem" }}>
              {activeContent}
            </Typography>
          </Grid2>

          {/* Main content grid */}
          <Grid2>
            <Box>{children}</Box>
          </Grid2>
        </Grid2>
      </Grid2>
    </Paper>
  );
}

import TuneIcon from "@mui/icons-material/Tune";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";

import ScheduleSlider from "@/features/projectSchedule/components/displayOptions/Slider";
import { DimensionsMenuStyle } from "@/features/projectSchedule/styles/styles";
import { DimensionsMenuTypes } from "@/features/projectSchedule/types/types";

export default function DimensionsMenu({
  ganttColumnWidth,
  GanttBoardHeight,
  onDisplayOptionsChange,
}: DimensionsMenuTypes) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // @todo: when we create custom reusable components like IconBUtton below
  // we should consider accessibility so that when we import it we can sklip all those lines
  // that add noise to the code
  return (
    <Box>
      <Tooltip title="Fine-tune Sizing">
        <IconButton onClick={handleClick}>
          <TuneIcon />
        </IconButton>
      </Tooltip>
      <Menu
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        sx={DimensionsMenuStyle}
        anchorEl={anchorEl}
        open={open}
        onMouseLeave={handleClose}
        onClose={handleClose}>
        <ScheduleSlider
          label="Column Width"
          value={ganttColumnWidth}
          onChange={(newWidth: number) => onDisplayOptionsChange("sliderWidth", newWidth)}
        />
        <ScheduleSlider
          label="Schedule Height"
          value={GanttBoardHeight}
          onChange={(newHeight: number) =>
            onDisplayOptionsChange("sliderHeight", newHeight)
          }
        />
      </Menu>
    </Box>
  );
}

import { Box, Tabs } from "@mui/material";
import { useState } from "react";

import {
  BoxStyle,
  CustomTabPanelStyle,
  TabsStyle,
} from "@/app/pages/strategyDefinition/styles/styles";
import Transactional from "@/features/transactional";
import CustomTab from "@/shared/components/CustomTab";
import CustomTabPanel from "@/shared/components/CustomTabPanel";
import RChip from "@/shared/components/RChip";

export default function ProcurementScenarios() {
  const [isActiveTab, setActiveTab] = useState("transactional");

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  return (
    <Box sx={BoxStyle}>
      <Box sx={TabsStyle}>
        <Tabs value={isActiveTab} onChange={handleChange} aria-label="tabs">
          <CustomTab<string>
            label={
              <Box display="flex" alignItems="center">
                Transactional
                <RChip label="LITE" />
              </Box>
            }
            value="transactional"
            setActiveTab={setActiveTab}
          />
          <CustomTab<string>
            label={
              <Box display="flex" alignItems="center">
                Bundled
                <RChip label="PRO" />
              </Box>
            }
            value="bundled"
            setActiveTab={setActiveTab}
            disabled
          />
          <CustomTab<string>
            label={
              <Box display="flex" alignItems="center">
                Integrated
                <RChip label="PRO" />
              </Box>
            }
            value="integrated"
            setActiveTab={setActiveTab}
            disabled
          />
          <CustomTab<string>
            label={
              <Box display="flex" alignItems="center">
                Cost
                <RChip label="ENTERPRISE" />
              </Box>
            }
            value="cost"
            setActiveTab={setActiveTab}
            disabled
          />
          <CustomTab<string>
            label={
              <Box display="flex" alignItems="center">
                Schedule
                <RChip label="ENTERPRISE" />
              </Box>
            }
            value="schedule"
            setActiveTab={setActiveTab}
            disabled
          />
          <CustomTab<string>
            label={
              <Box display="flex" alignItems="center">
                Emissions
                <RChip label="ENTERPRISE" />
              </Box>
            }
            value="emissions"
            setActiveTab={setActiveTab}
            disabled
          />
        </Tabs>
      </Box>
      <Box sx={CustomTabPanelStyle}>
        <CustomTabPanel value={isActiveTab} name="transactional">
          <Transactional />
        </CustomTabPanel>
        <CustomTabPanel value={isActiveTab} name="bundled">
          Scenario B
        </CustomTabPanel>
        <CustomTabPanel value={isActiveTab} name="integrated">
          Scenario C
        </CustomTabPanel>
        <CustomTabPanel value={isActiveTab} name="cost">
          Scenario D
        </CustomTabPanel>
        <CustomTabPanel value={isActiveTab} name="schedule">
          Scenario E
        </CustomTabPanel>
        <CustomTabPanel value={isActiveTab} name="emissions">
          Scenario F
        </CustomTabPanel>{" "}
      </Box>
    </Box>
  );
}

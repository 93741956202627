import { useParams } from "react-router-dom";

import { OutcomeOverview } from "@/features/outcomeOverview";
import RLoading from "@/shared/components/RLoading";
import useProjectWBSContext from "@/shared/context/projectWBS/useProjectWBSContext";

export default function ScenarioOverview() {
  const { outcome: wbsEntryId } = useParams();
  const { getWBSEntryById } = useProjectWBSContext();
  const WBSEntry = getWBSEntryById(wbsEntryId);

  return WBSEntry ? <OutcomeOverview wbsEntry={WBSEntry} /> : <RLoading />;
}

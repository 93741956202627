import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";

import { getProjectOrBudgetEndDate } from "@/features/outcomeOverview/helpers/helper";
import {
  OverviewDetailCardStyle,
  OverviewDetailCardDateStyle,
  DateFieldStyle,
} from "@/features/outcomeOverview/styles/styles";
import { RDateField } from "@/shared/components/inputs";
import {
  WBSEntryModel,
  WBSEntryViewModel,
} from "@/shared/context/projectWBS/projectWBSModel";

export default function OverviewDetailCard({
  wbsEntry,
}: {
  wbsEntry: WBSEntryModel | WBSEntryViewModel;
}) {
  const endDate = getProjectOrBudgetEndDate(wbsEntry);

  return (
    <Box sx={OverviewDetailCardStyle}>
      <Typography variant="h1">
        {wbsEntry.wbsCode} {wbsEntry.name.toUpperCase()}
      </Typography>
      <Typography variant="h3">Level {wbsEntry.level}</Typography>
      <Box>
        <Typography variant="subtitle1">Scenario</Typography>
        <Typography variant="h3">Transactional</Typography>
      </Box>
      <Box sx={OverviewDetailCardDateStyle}>
        <Box>
          <Typography variant="subtitle1">From</Typography>
          <Typography variant="h3">
            <RDateField
              date={dayjs(wbsEntry.startDate)}
              style={DateFieldStyle}
              disabled={true}
            />
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle1">To</Typography>
          <Typography variant="h3">
            <RDateField date={dayjs(endDate)} style={DateFieldStyle} disabled={true} />
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

import Box from "@mui/material/Box";
import { TypographyProps } from "@mui/system";
import { useState } from "react";

import { RNumberInput, RNumberLabel } from "@/shared/components/inputs";

import { getBoxStyles } from "../styles/styles";

type RNumberEditableProps = {
  value: string | number | null | undefined;
  onInputChange: (value: number) => void;
  category:
    | "currency"
    | "emissions"
    | "duration"
    | "percentage"
    | "quantity"
    | "outcome"
    | "per outcome";
  customUnit?: string;
  decimalScaleInput?: number;
  decimalScaleLabel?: number;
  allowNegative?: boolean;
  range?: { min: number; max: number };
  typographyProps?: TypographyProps;
};

export default function RNumberInputLabel({
  value,
  onInputChange,
  category,
  customUnit,
  allowNegative = false,
  decimalScaleInput = 2,
  decimalScaleLabel,
  range,
  typographyProps,
}: RNumberEditableProps) {
  const [isEditing, setIsEditing] = useState(false);
  const decimalForLabel = decimalScaleLabel ?? decimalScaleInput;
  // Ensure value defaults to 0 if it's undefined, null, or NaN
  const normalizedValue = value == null || isNaN(Number(value)) ? 0 : Number(value);

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleBlur = () => {
    // Set a short delay to allow onInputChange to complete first
    setTimeout(() => setIsEditing(false), 0);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      // Set a short delay to allow onInputChange to complete first
      setTimeout(() => setIsEditing(false), 0);
    }
  };

  return (
    <Box
      onDoubleClick={handleDoubleClick}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      sx={getBoxStyles(isEditing)}>
      {isEditing ? (
        <RNumberInput
          value={normalizedValue}
          onInputChange={(newValue) => {
            onInputChange(newValue);
            setIsEditing(false);
          }}
          range={range}
          decimalScale={decimalScaleInput}
          allowNegative={allowNegative}
          typographyProps={typographyProps}
          category={category}
        />
      ) : (
        <RNumberLabel
          value={value}
          category={category}
          customUnit={customUnit}
          decimalScale={decimalForLabel}
          typographyProps={typographyProps}
        />
      )}
    </Box>
  );
}

import { Box } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import OverviewTableRow from "@/features/outcomeOverview/components/table/OverviewTableRow";
import { populateSchema } from "@/features/outcomeOverview/helpers/helper";
import { OverviewTableStyle } from "@/features/outcomeOverview/styles/styles";
import { WBSEntryModel } from "@/shared/context/projectWBS/projectWBSModel";

import OverviewTableSchema from "./overviewTableSchema.json";

export default function OverviewTable({ wbsEntry }: { wbsEntry: WBSEntryModel }) {
  const wbsEntries = populateSchema(OverviewTableSchema, wbsEntry);

  return (
    <TableContainer component={Box} sx={OverviewTableStyle}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Cost</TableCell>
            <TableCell>Duration</TableCell>
            <TableCell>Emissions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {wbsEntries.map((row) => (
            <OverviewTableRow key={row.id} wbsEntry={wbsEntry} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

type ParametricApiEndpoints = {
  GET_PARAMETERS: string;
  UPDATE_PARAMETER: string;
  CREATE_PARAMETER: string;
};
export const PARAMETRIC_API_ENDPOINTS: ParametricApiEndpoints = {
  GET_PARAMETERS: "/api/parametricCost/getAllParametricCosts/{outcomeId}",
  UPDATE_PARAMETER: "/api/parametricCost/{parameterId}",
  CREATE_PARAMETER: "/api/parametricCost",
};

import { AxiosError, AxiosResponse } from "axios";

import { PROJECTS_API_ENDPOINTS } from "@/features/portfolio/api/endpoints";
import apiRequests from "@/shared/config/axiosConfig";
type ChangeOrderResponse = {
  message: string;
  statusCode: number;
};

export default async function changeOrder(sourceRowId: string, targetRowId: string) {
  try {
    const url = PROJECTS_API_ENDPOINTS.PATCH_PROJECT_ORDER;

    const response: AxiosResponse<ChangeOrderResponse> = await apiRequests.patch(url, {
      sourceRowId,
      targetRowId,
    });

    const { data, status } = response;

    if (status >= 200 && status < 300) {
      return data;
    }

    throw new Error(`Unknown error occurred with status code ${status}`);
  } catch (e) {
    // @todo Handle Error Below
    const error = e as AxiosError;
    switch (error.response?.status) {
      case 400:
        throw new Error("400: Bad Request");
      case 401:
        throw new Error("401: Unauthorized");
      case 404:
        throw new Error("404: Not Found");
      default:
        throw new Error(
          `Unknown error occurred with status code ${error.response?.status}`
        );
    }
  }
}

type ResourceCatalogueEndpoints = {
  GET_RESOURCE_CATALOGUE_FOR_DELIVERABLE: string;
  EDIT_RESOURCE: string;
  ADD_RESOURCE: string;
  DELETE_RESOURCE: string;
  CREATE_MULTIPLE_RESOURCES: string;
};
export const RESOURCE_CATALOGUE_ENDPOINTS: ResourceCatalogueEndpoints = {
  GET_RESOURCE_CATALOGUE_FOR_DELIVERABLE: "/api/resources/deliverable/{deliverableId}",
  EDIT_RESOURCE: "/api/resources",
  ADD_RESOURCE: "/api/resources",
  CREATE_MULTIPLE_RESOURCES: "/api/resources/CreateMultipleResources",
  DELETE_RESOURCE: "/api/resources/{resourceId}",
};

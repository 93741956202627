import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import PropagateLoader from "react-spinners/PropagateLoader";

const ContainerStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  width: "100%",
  padding: "5rem",
  paddingBottom: "25rem",
  paddingRight: "10rem",
};

const LoaderStyle = {
  marginBottom: "2rem",
};

const HeadingStyle = {
  marginTop: "3rem",
  fontWeight: "bold",
  color: "var(--primary-color)",
};

const SubheadingStyle = {
  marginTop: "3rem",
  color: "var(--ecerto-cool-grey)",
};

type RLoadingProps = {
  text?: string;
  subText?: string;
};

export default function RLoading({
  text = "We are retrieving your project data",
  subText = "Thank you for your patience",
}: RLoadingProps) {
  return (
    <Box sx={ContainerStyle}>
      <PropagateLoader
        color="#003057"
        size={15}
        speedMultiplier={0.55}
        cssOverride={LoaderStyle}
      />
      <Typography variant="h3" sx={HeadingStyle}>
        {text}
      </Typography>
      <Typography variant="h5" sx={SubheadingStyle}>
        {subText}
      </Typography>
    </Box>
  );
}

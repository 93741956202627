import { OverviewItemsModel } from "../model/baselineOverviewModels";

const BaselineOverviewSchema: OverviewItemsModel[] = [
  {
    id: "cost/unit",
    title: "Cost / Unit",
    amount: 0,
    color: "var(--ecerto-green)",
    category: "per outcome",
    customUnit: "",
  },
  {
    id: "deterministicFixedCost",
    title: "Cost",
    amount: 0,
    color: "var(--ecerto-green)",
    category: "currency",
  },
  {
    id: "modeDuration",
    title: "Duration",
    amount: 0,
    color: "var(--ecerto-green)",
    category: "duration",
  },
  {
    id: "emissions",
    title: "Emissions",
    amount: 0,
    color: "var(--ecerto-green)",
    category: "emissions",
  },
  {
    id: "standbyCostDay",
    title: "Standby Cost / Day",
    amount: 0,
    color: "var(--ecerto-green)",
    category: "currency",
  },
  {
    id: "impactCost",
    title: "Impact: Cost",
    amount: 0,
    color: "var(--ecerto-red)",
    category: "currency",
  },
  {
    id: "impactDuration",
    title: "Impact: Duration",
    amount: 0,
    color: "var(--ecerto-red)",
    category: "duration",
  },
  {
    id: "impactEmissions",
    title: "Impact: Emissions",
    amount: 0,
    color: "var(--ecerto-red)",
    category: "emissions",
  },
];

export default BaselineOverviewSchema;

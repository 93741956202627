import { AuthenticatedTemplate } from "@azure/msal-react";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useState, MouseEvent } from "react";
import { Link } from "react-router-dom";

import NavbarUserMenu from "@/features/navigation/navbar/components/NavbarUserMenu";
import { getUserAvatar } from "@/features/navigation/navbar/helpers/navbarHelpers";
import {
  ContainerNavbarTitleStyle,
  ImageNavbarTitleStyle,
  TextNavbarTitleStyle,
  AvatarStyle,
  IconButtonStyle,
  AppBarStyle,
} from "@/features/navigation/navbar/styles/navbarStyles";
import WhiteLogo from "@/shared/assets/logos/eCERTO_flat_white.png";
import RChip from "@/shared/components/RChip";
import { getUserInfo } from "@/shared/store/userInfo";

export default function Navbar() {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const userInfo = getUserInfo();

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  return (
    <>
      <AppBar position="fixed" sx={(theme) => AppBarStyle(theme)}>
        <Toolbar>
          <Box sx={ContainerNavbarTitleStyle}>
            <Box component="img" src={WhiteLogo} alt="Logo" sx={ImageNavbarTitleStyle} />
            <Typography
              component={Link}
              to="/"
              noWrap
              variant="h6"
              sx={TextNavbarTitleStyle}>
              INTEGRATI
            </Typography>
            <RChip label="LITE" size="medium" />
          </Box>

          <AuthenticatedTemplate>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={IconButtonStyle}>
                <Avatar alt="eCERTO" sx={AvatarStyle}>
                  {getUserAvatar(userInfo.fullName)}
                </Avatar>
              </IconButton>
            </Tooltip>
            <NavbarUserMenu
              anchorElUser={anchorElUser}
              setAnchorElUser={setAnchorElUser}
            />
          </AuthenticatedTemplate>
        </Toolbar>
      </AppBar>
    </>
  );
}

import { Form, Formik, FormikProps } from "formik";
import { RefObject } from "react";

import ReusableAutocomplete from "@/shared/components/ReusableAutocomplete";
import useProjectWBSContext from "@/shared/context/projectWBS/useProjectWBSContext";
import { WBSEntryViewModel } from "@/shared/types/projectWBSModel";

import { WBSEntryModel } from "../../../shared/types/projectScopeModel";
import {
  getDeliverablesChildrenForClone,
  getDeliverablesForClone,
  getProjectsForClone,
  addClonedDeliverable,
} from "../api/projectScopeService";

export type FormValues = {
  project: { id: string; label: string };
  option: { id: string; label: string };
  outcome: { id: string; label: string };
  level2: { id: string; label: string };
  level3: { id: string; label: string };
  level4: { id: string; label: string };
};

type AddCloneDeliverableFormProps = {
  handleClose: () => void;
  data?: WBSEntryViewModel | null;
  clearRowSelection: () => void;
  innerRef: RefObject<FormikProps<FormValues>>;
};

export default function AddCloneDeliverableForm({
  data,
  handleClose,
  clearRowSelection,
  innerRef,
}: AddCloneDeliverableFormProps) {
  const { refetchProjectWBS } = useProjectWBSContext();

  return (
    <Formik<FormValues>
      initialValues={{
        project: { id: "", label: "" },
        option: { id: "", label: "" },
        outcome: { id: "", label: "" },
        level2: { id: "", label: "" },
        level3: { id: "", label: "" },
        level4: { id: "", label: "" },
      }}
      onSubmit={async (values) => {
        let oldDeliverable;
        switch (data?.level) {
          case 0:
            oldDeliverable = values.outcome.id;
            break;
          case 1:
            oldDeliverable = values.level2.id;
            break;
          case 2:
            oldDeliverable = values.level3.id;
            break;
          case 3:
            oldDeliverable = values.level4.id;
            break;
          default:
            break;
        }

        const modelPayload = {
          newDeliverableParentId: data?.id.toString() ?? "",
          newProjectId: data?.projectId.toString() ?? "",
          oldDeliverableId: oldDeliverable?.toString() ?? "",
          newOrder: data?.children?.length ? data.children.length + 1 : 0,
        };
        try {
          await addClonedDeliverable(modelPayload);
        } catch (error) {
          // @todo handle error
        } finally {
          refetchProjectWBS();
          handleClose();
          clearRowSelection();
        }
      }}
      innerRef={innerRef}>
      {({ values, setFieldValue, handleBlur }) => (
        <Form
          style={{
            gap: "1rem",
            flexDirection: "column",
            display: "flex",
          }}>
          <ReusableAutocomplete
            label="Project"
            id="project"
            name="project"
            value={values.project}
            handleChange={setFieldValue}
            handleBlur={handleBlur}
            func={getProjectsForClone}
          />

          <ReusableAutocomplete
            label="Option"
            id="option"
            name="option"
            value={values.option}
            handleChange={setFieldValue}
            handleBlur={handleBlur}
            func={async () => {
              const val: WBSEntryModel[] = await getProjectsForClone();
              const projects = val.find((val_1) => val_1.id === values.project.id);
              return projects?.children;
            }}
          />
          {values.project && (
            <ReusableAutocomplete
              label="Select Outcome"
              id="outcome"
              name="outcome"
              value={values.outcome}
              handleChange={setFieldValue}
              handleBlur={handleBlur}
              func={() => getDeliverablesForClone(values.option.id)}
            />
          )}
          {data && data?.level >= 1 && values.outcome && (
            <ReusableAutocomplete
              label="Select Level 2 Deliverable"
              id="level2"
              name="level2"
              value={values.level2}
              handleChange={setFieldValue}
              handleBlur={handleBlur}
              func={() => getDeliverablesChildrenForClone(values.outcome.id)}
            />
          )}
          {data && data?.level >= 2 && values.level2 && (
            <ReusableAutocomplete
              label="Select Level 3 Deliverable"
              id="level3"
              name="level3"
              value={values.level3}
              handleChange={setFieldValue}
              handleBlur={handleBlur}
              func={() => getDeliverablesChildrenForClone(values.level2.id)}
            />
          )}
          {data && data?.level >= 3 && values.outcome && (
            <ReusableAutocomplete
              label="Select Level 4 Deliverable"
              id="level4"
              name="level4"
              value={values.level4}
              handleChange={setFieldValue}
              handleBlur={handleBlur}
              func={() => getDeliverablesChildrenForClone(values.level3.id)}
            />
          )}
          {/* <Box display="flex" justifyContent="flex-end" mt={1} gap={1}>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button type="submit" variant="contained" disabled={!isValid || isSubmitting}>
              Add From Existing
            </Button>
          </Box> */}
        </Form>
      )}
    </Formik>
  );
}

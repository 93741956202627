export type ProjectApiEndpoints = {
  GET_PORTFOLIO: string;
  GET_PROJECTS: string;
  GET_PROJECT: string;
  BATCH_DELETE_PROJECTS: string;
  PATCH_PROJECT_ORDER: string;
  POST_CLONE_PROJECT: string;
  POST_CLONE_CONCEPT: string;
};

export const PROJECTS_API_ENDPOINTS: ProjectApiEndpoints = {
  GET_PORTFOLIO: "/api/projects/getPortfolio",
  GET_PROJECTS: "/api/projects", // used for 4 calls adding and editing projects and concepts. I would prefer to have separate endpoints for each.
  GET_PROJECT: "/api/projects/{uuid}",
  BATCH_DELETE_PROJECTS: "/api/projects/batchDelete",
  PATCH_PROJECT_ORDER: "/api/projects",
  POST_CLONE_PROJECT: "/api/projects/cloneProject",
  POST_CLONE_CONCEPT: "/api/projects/cloneConcept",
};

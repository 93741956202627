import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";

import { HeadCellTypes } from "@/features/projectSchedule/types/types";

const getHeadStyles = (width: string) => ({
  width,
  color: "hsl(210, 100%, 96%)",
  marginBottom: "0.375rem", // 6px / 16px = 0.375rem
  "&:first-of-type": {
    marginLeft: "0.9375rem", // 15px / 16px = 0.9375rem
  },
});
export default function HeadCell({ children, width }: HeadCellTypes) {
  return (
    <Box role="columnheader" sx={getHeadStyles(width)}>
      <Typography>{children}</Typography>
    </Box>
  );
}

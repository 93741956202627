import ErrorIcon from "@mui/icons-material/Error";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

import { PrimaryButton } from "@/shared/components/buttons";

export default function InvalidConceptID() {
  const navigate = useNavigate();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        padding: "32px",
      }}>
      <ErrorIcon
        color="error"
        style={{ fontSize: "calc(32px + 1.5vw)" }} // Adjust values as needed
      />

      <Typography
        component="h1"
        gutterBottom
        style={{
          marginTop: "16px",
          fontSize: "calc(24px + 0.5vw)", // Adjust base and scaling values
          whiteSpace: "nowrap",
        }}>
        This concept does not exist
      </Typography>

      <PrimaryButton onClick={() => navigate("/portfolio")}>
        Go to Portfolio
      </PrimaryButton>

      <Typography variant="body1" component="p" mt={3}>
        Please select a valid concept from the portfolio.
      </Typography>
    </div>
  );
}

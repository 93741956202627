import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import { OrgChart } from "d3-org-chart";
import { useContext } from "react";

import { WBSLevelsContext } from "@/shared/context/WBSLevelsContext";

import { IData } from "../types/orgChartModels";

export default function OrgSearch({ chart }: { chart: OrgChart<IData> }) {
  const { setExpandedWBSEntries, setHighlightedWBSEntries } =
    useContext(WBSLevelsContext);
  const filterChart = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchQuery = e.target.value;

    // Clear previous higlighting
    chart.clearHighlighting();

    // Get chart nodes
    const data = chart.data();
    if (!data) return;
    // Loops through all nodes and highlights / expands the ones that match the search query
    // If the nodes don't match, they are collapsed
    // Step 1: Identify matching nodes and mark them as highlighted
    const matchingNodes = new Set<string>();
    data.forEach((node) => {
      if (searchQuery && node.name.toLowerCase().includes(searchQuery.toLowerCase())) {
        matchingNodes.add(node.id);
      }
    });

    // Step 2: Build a parent-child map
    const parentMap = new Map<string, string>();
    data.forEach((node) => {
      if (node.parentId) {
        parentMap.set(node.id, node.parentId);
      }
    });

    // Step 3: Find ancestors of matching nodes
    const ancestors = new Set<string>();
    matchingNodes.forEach((nodeId: string) => {
      let currentId: string = nodeId;
      while (parentMap.has(currentId)) {
        const parentId = parentMap.get(currentId);
        if (parentId) {
          ancestors.add(parentId);
          currentId = parentId; // Move up the tree
        }
      }
    });

    // Step 4: Update nodes based on matching nodes, their ancestors, and highlight matching nodes
    const updatedNodes = data.map((node) => {
      if (matchingNodes.has(node.id)) {
        // If node matches, mark as highlighted and expanded
        return { ...node, _highlighted: true, _expanded: true };
      }
      if (ancestors.has(node.id) && node.level > 0) {
        // If node is an ancestor of a matching node, expand it
        return { ...node, _expanded: true };
      }
      // Otherwise, collapse the node
      return { ...node, _expanded: false };
    });

    setExpandedWBSEntries(updatedNodes.filter((d) => d._expanded).map((d) => d.id));
    const highlightedNodeIds = updatedNodes
      .filter((d) => d._highlighted)
      .map((d) => d.id);
    setHighlightedWBSEntries(highlightedNodeIds);
  };

  return (
    <TextField
      sx={{
        color: "primary",
        // change text color to secondary and line
        "& .MuiInputBase-input": {
          color: "#003057",
          borderBottom: "1px solid #003057",
        },
        "& .MuiInput-underline:before": {
          borderBottom: "1px solid #003057",
        },
      }}
      id="search"
      label={<SearchIcon color="primary" />}
      type="search"
      variant="standard"
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => filterChart(e)}
    />
  );
}
